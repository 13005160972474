<template lang="html">
  <section class="co-ekon-banner mt-8">
    <div class="grid grid-nogutter surface-0 text-800 p-3 md:p-8">
      <div class="col-12 col-lg-6 overflow-hidden  ">
        <img :src="ekon.img" alt="Image hero" class="ekon-image" />
      </div>
      <div
        class="col-12 col-lg-6 
            pb-6 md:p-6 text-left md:text-left flex align-items-center 
            "
      >
        <section>
          <h1 class="text-5xl md:text-6xl font-bold mb-3 ekon-color">
            {{ ekon.title }}
          </h1>
          <p
            class="mt-0 mb-4 md:mr-8 text-900 line-height-3"
            style="white-space: pre-wrap;"
          >
            {{ ekon.description }}
          </p>
          <Button
            v-for="button in ekon.buttons"
            :key="button.label"
            :label="button.label"
            class="ekon-button mr-3 p-button-raised"
            @click="goEkon()"
          />
        </section>
      </div>
    </div>
  </section>
</template>

<script lang="js">
import axios from 'axios'
  export default  {
    name: 'co-ekon-banner',
    props: [],
    mounted () {
      axios.get('json/ekon.json').then((response)=>{
        this.ekon = response.data;
      })
    },
    data () {
      return {
        ekon:{}
      }
    },
    methods: {
      goEkon(){
        location.replace(this.ekon.link);
      }
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
$primary-color: "#F8B505";
.co-ekon-banner {
}
.ekon-image {
  /* clip-path: polygon(0 -75%, 80% 50%, 80% 50%, 0 195%);
  clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 55% 0, 82% 50%, 55% 100%, 0% 100%); */
  width: -webkit-fill-available;
  max-height: 25vh;
  @media (min-width:768px){transform: scale(0.7);}
}
.ekon-color {
  color: #ff6a39;
}
.ekon-bg {
  background-color: #ff6a39;
}
.ekon-border {
  border-color: #ff6a39;
}
.ekon-button {
  border-color: #ff6a39 !important;
  background-color: #ff6a39 !important;
  color: white !important;
  &:hover {
    border-color: #ff6a39 !important;
    background-color: darken(#ff6a39, 15%) !important;
    color: white;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 106, 57, 0.5) !important;
  }
}
@media (min-width: 768px) {
  .ekon-image {
    max-height: 45vh;
    width: 100%;
  }
}
</style>
