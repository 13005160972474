<template>
    <div>
        <ScrollTop :threshold=parseInt(altura) /> 
    </div>
</template>
<script>
import ScrollTop from 'primevue/scrolltop';
export default {
    name: "coScrollTop" ,
    components :{
       ScrollTop:ScrollTop 
    },
    props:['altura']
    
}
</script>

<style  scoped>

</style>