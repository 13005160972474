var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mapas mt-5 mb-5 p-5"},[_c('div',{staticClass:"row justify-content-around",staticStyle:{"row-gap":"1rem"}},[_c('div',{staticClass:"col-md-6 pt-3"},[_c('h3',{staticClass:"text-primary"},[_vm._v("EXTOM MADRID")]),_c('p',[_vm._v("Calle Canillas, 31 ")]),_c('p',[_vm._v(" 28002 Madrid ")]),_vm._m(0),_c('GmapMap',{staticClass:"mapa",attrs:{"center":{lat:40.4422007, lng:-3.6748816},"zoom":14}},[_c('GmapMarker',{key:_vm.clave,attrs:{"label":{
                'text': 'Calle Canillas, 31',
                'fontWeight': 'bold',
                'fontSize': '15px'
                },"position":{lat:40.4422007, lng:-3.6748816},"clickable":true,"draggable":false}})],1)],1),_c('div',{staticClass:"col-md-6 pt-3"},[_c('h3',{staticClass:"text-primary"},[_vm._v("EXTOM BARCELONA")]),_c('p',[_vm._v("Plaza Urquinaona, 6, 18ºC")]),_c('p',[_vm._v(" 08010 Barcelona ")]),_vm._m(1),_c('GmapMap',{staticClass:"mapa",attrs:{"center":{lat:41.3898233, lng:2.1728722},"zoom":14}},[_c('GmapMarker',{key:_vm.index,attrs:{"label":{
                'text': 'Plaza Urquinaona, 6, 18ºC',
                'fontWeight': 'bold',
                'fontSize': '15px' 
            },"position":{lat:41.3898233, lng:2.1728722},"clickable":true,"draggable":false}})],1)],1),_c('div',{staticClass:"col-md-6 pt-3"},[_c('h3',{staticClass:"text-primary"},[_vm._v("EXTOM GRANADA")]),_c('p',[_vm._v("Calle Bruno, 1")]),_c('p',[_vm._v(" 18200 Maracena ")]),_vm._m(2),_c('GmapMap',{staticClass:"mapa",attrs:{"center":{lat:37.20889071293974, lng:-3.635438982209726},"zoom":14}},[_c('GmapMarker',{key:_vm.index,attrs:{"label":{
                'text': 'Calle Bruno, 1',
                'fontWeight': 'bold',
                'fontSize': '15px' 
            },"position":{lat:37.20889071293974, lng:-3.635438982209726},"clickable":true,"draggable":false}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{staticClass:"pl-2 pr-2 telf-flotante-num order-2 text-primary",attrs:{"href":"tel:958466247"}},[_vm._v(" Tél.: 91 356 06 25")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{staticClass:"pl-2 pr-2 telf-flotante-num order-2 text-primary",attrs:{"href":"tel:958466247"}},[_vm._v(" Tél.: 93 342 92 67")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('a',{staticClass:"pl-2 pr-2 telf-flotante-num order-2 text-primary",attrs:{"href":"tel:958687267"}},[_vm._v(" Tél.: 958 68 72 67")])])}]

export { render, staticRenderFns }