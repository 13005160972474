<template lang="html">
  <section class="co-servicios md:ml-8 md:mr-8">
    <div class="mb-3 font-bold text-2xl">
        <span class="text-primary text-5xl">Nuestros servicios</span>
      </div>
    <Carousel
      :value="servicios"
      :responsiveOptions="responsiveCarousel"
      :numVisible="3"
      :circular="servicios && servicios.length > 3"
      :autoplayInterval="15000"
      indicatorsContentClass="d-none"
      containerClass=""
    >
      <template #item="slotProps">
        <Card class="service-card">
          <template #header>
            <router-link :to="slotProps.data.xto ? slotProps.data.xto : ''">
            <img
              alt=""
              class="service-img"
              :src="slotProps.data.ximg"
              @load="imgLoaded"
            />
            </router-link>
          </template>
          <template #title>
            <h2 class="text-3xl font-bold mb-3">
              {{ slotProps.data.xtitulo }}
            </h2>
          </template>
          <template #subtitle>
            <div>{{ slotProps.data.xsubtitle }}</div>
          </template>
          <template #content>
            <p>{{ slotProps.data.xdescripcion }}</p>
          </template>
          <template #footer>
            <vs-button size="large" :to="slotProps.data.xto">
              <span>Saber más</span>
            </vs-button>
          </template>
        </Card>
      </template>
    </Carousel>
  </section>
</template>

<script lang="js">
import API_URL from '../backend';
import ViewService from '../services/view.service'
  export default  {
    name: 'co-servicios',
    props: [],
    mounted () {
      ViewService.getServicios().then((response)=>{
        this.servicios = response.data;
        this.servicios.forEach(servicio=>{
          servicio.ximg = API_URL + servicio.ximg;
        })
      })
    },
    data () {
      return {
        servicios:null,
        imgsLoaded:[],
        responsiveCarousel:[
          {
            breakpoint:"576px",
            numVisible:1,
            numScroll:2
            
          },
          {
            breakpoint:"768px",
            numVisible:1,
            numScroll:2
          },
          {
            breakpoint:"1280px",
            numVisible:2,
            numScroll:2
          }
        ]
      }
    },
    methods: {
      imgLoaded(){
        this.imgsLoaded.push(true);
        if(this.imgsLoaded.length == this.servicios.length)
          this.$emit('loaded', true);
      }
    },
    computed: {

    }
}
</script>

<style lang="scss">
.co-servicios {
}
.service-card {
  width: 100%;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.171);
}
.p-card .p-card-title {
    height: 5rem;
}
@media (min-width: 568px) {
  .service-card {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .service-card {
    width: 95%;
  }
  @media (min-width:1280px){
    .p-card {
      height:100%
    }
    .p-card-header {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: all .5s;
  transition: all .5s;
}
.p-card-body {
  height: 125px;
  padding: 20px;
  position: relative;
  
}
.p-carousel-item {
  margin-bottom: 1rem;
}
.p-card-content	{
    /* left: 20px;
    right: 20px;
    bottom: 56px; */
    margin: 0;
    padding: 0;
    color: #666C74;
    line-height: 27px;
    opacity: 0;
    -webkit-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.p-card-footer{
  opacity: 0;
    -webkit-transform: translateY(45px);
    transform: translateY(45px);
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}
.service-img {
  overflow: hidden;
  background-color: #000;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  clip-path: polygon(100% 0, 0 0, 0 100%, 50% 90%, 100% 100%);
  display: block;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, transform 0.5s;
  transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
  transition-delay: 0.2s;
}
.service-card:hover {
  .p-card-header {
    height: 130px;
    background-color: rgba(0, 0, 0, 0.932);
    img {
      opacity: 0.6;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
  .p-card-body{
    height: 100%;
  }
  .p-card-content{
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .p-card-footer{
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
}
  }
}
</style>
