import Vue from "vue";
import VueRouter from "vue-router";
import vHome from "../views/vHome.vue";
import vContact from "../views/vContact.vue";
import AuthService from "../services/auth.service";
import vHomeHeros from "../views/BackOffice/vHomeHeros.vue";
//import vHomeServicios from "../views/BackOffice/vHomeServicios.vue";
import vBackOffice from "../views/vBackOffice.vue";
import vPrivacidad from '../views/vPrivacidad.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: vHome,
  },
  {
    path: "/nosotros",
    name: "Nosotros",
  },
  /* /contact/servicios/3 */
  {
    path: "/contact/:origen1/:origen2",
    name: "Contact 2 origenes",
    component: vContact,
  },
  {
    path: "/contact/:origen1",
    name: "Contact 1 origen",
    component: vContact,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: vContact
  },{
    path: '/politica-de-privacidad',
    name: 'Privacidad',
    component: vPrivacidad
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vAbout.vue"),
  },
  {
    path: "/info/:enlace/:id",
    component: () => import("../views/vServicios"),
    name: "Servicios",
  },
  {
    path: "/404",
    component: () => import("../views/vHome"),
    name: "404",
  },
  {
    path: "/admin",
    component: () => import("../views/vAdminLogin.vue"),
    name: "Admin Login",
    meta: { auth: false }
  },
  {
    path: "/admin/dashboard",
    component: vBackOffice,
    name: "Dashboard",
    meta: { auth: true },
    children: [
      {
        path:'home/marcas',
        component: ()=> import("../views/BackOffice/vHomeMarcas.vue"),
        name:'HomeMarcas'
      },
      {
        path:'home/heros',
        alias: 'home',
        component: vHomeHeros,
        name:'HomeHeros'
      },
      {
        path:'home/nav',
        component: ()=> import("../views/BackOffice/vHomeNavbar.vue"),
        name:'Navbar'
      },/* 
      {
        path:'home/perfiles',
        component:() => import ("../views/BackOffice/vHomePerfiles.vue"),
        name:'HomePerfiles'
      }, */
      {
        path:'perfiles',
        component:() => import ("../views/BackOffice/vBloquesPerfiles.vue"),
        name:'BloquesPerfiles'
      },/* 
      {
        path:'home/servicios',
        component: vHomeServicios,
        name:'HomeServicios'
      }, */
      {
        path:'servicios',
        component:() => import ("../views/BackOffice/vBloquesServicios.vue"),
        name:'BloquesServicios'
      },
      {
        path:'buzon/contacto',
        alias: 'buzon',
        name:"Buzon-Contacto",
        component: () => import ("../views/BackOffice/vBuzonContacto.vue"),
      },
      {
        path:'buzon/cv',
        name:"Buzon-CV",
        component: () => import ("../views/BackOffice/vBuzonCV.vue"),
      },
      {
        path:'buzon/descargasFolletos',
        name:"Buzon-DescargasFolletos",
        component: () => import ("../views/BackOffice/vBuzonDescargas.vue"),
      },
      {
        path:'buzon/config',
        name:"Buzon-Config",
        component: () => import ("../views/BackOffice/vBuzonConfig.vue"),
      },
      {
        path:'datosWeb',
        name:"Datos Web",
        component: () => import ("../views/BackOffice/vDatosWeb.vue"),
      },
      { path: "*", component: vHome },
      { path: "404", component: vHome }
    ]
  },
];

const router = new VueRouter({
  //mode: "history",
  routes,
});
router.beforeEach(async (to, from, next) => {
  const response = await AuthService.esAdministrador();
  if ("auth" in to.meta && to.meta.auth && !response.data.esAdministador) {
    next("/admin");
  } else if (
    "auth" in to.meta &&
    !to.meta.auth &&
    response.data.esAdministador
  ) {
    next("/admin/dashboard");
  } else {
    next();
  }
});
export default router;
