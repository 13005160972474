import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';


class HerosService {
    getTodos(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/heros/todos',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    actualizarHero(hero){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/heros/update',
            data:hero,
            headers: authHeader()
        }
        console.log(axiosRequest);
        return axios(axiosRequest);
    }
    nuevoHero(hero){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/heros/nuevo',
            data:hero,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    borrarHero(heroId){
        const axiosRequest = {
            method: 'delete',
            url: API_URL+'/admin/heros/borrar/'+heroId,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }

}
export default new HerosService();