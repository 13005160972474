import { render, staticRenderFns } from "./coEmailFlotante.vue?vue&type=template&id=0afe96e5&scoped=true&lang=html&"
import script from "./coEmailFlotante.vue?vue&type=script&lang=js&"
export * from "./coEmailFlotante.vue?vue&type=script&lang=js&"
import style0 from "./coEmailFlotante.vue?vue&type=style&index=0&id=0afe96e5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0afe96e5",
  null
  
)

export default component.exports