import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';

class EstadisticasService {
    servicioMasVisitado(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/estadisticas/servicioMasVisitado',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    perfilMasVisitado(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/estadisticas/perfilMasVisitado',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    visitasPerfiles(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/estadisticas/visitasPerfilesTot',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    visitasServicios(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/estadisticas/visitasServiciosTot',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    todasVisitasPorServicioPerfil(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/estadisticas/todasVisitasPorServicioPerfil',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
}
export default new EstadisticasService();