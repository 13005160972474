<template>
    <div class="container mapas mt-5 mb-5 p-5">
        <div class="row justify-content-around" style = "row-gap:1rem">
            <div class="col-md-6 pt-3">
                <h3 class="text-primary">EXTOM MADRID</h3>
                <p>Calle Canillas, 31 </p>
                <p>  28002 Madrid </p>
                <p><a class="pl-2 pr-2 telf-flotante-num order-2 text-primary" href="tel:958466247"> Tél.: 91 356 06 25</a></p>                 
            <GmapMap class="mapa"
                :center="{lat:40.4422007, lng:-3.6748816}"
                :zoom="14"
            >           
            <GmapMarker                
                :label="{
                    'text': 'Calle Canillas, 31',
                    'fontWeight': 'bold',
                    'fontSize': '15px'
                    }"
                :key="clave"
                :position="{lat:40.4422007, lng:-3.6748816}"
                :clickable="true"
                :draggable="false"
            />            
            </GmapMap>  
            </div> 
            <div class="col-md-6 pt-3">   
                <h3 class="text-primary">EXTOM BARCELONA</h3>
                <p>Plaza Urquinaona, 6, 18ºC</p>
                <p>  08010 Barcelona  </p>
                <p><a class="pl-2 pr-2 telf-flotante-num order-2 text-primary" href="tel:958466247"> Tél.: 93 342 92 67</a></p>  
            <GmapMap class="mapa"
                :center="{lat:41.3898233, lng:2.1728722}"
                :zoom="14"
            >
            <GmapMarker
                :label="{
                    'text': 'Plaza Urquinaona, 6, 18ºC',
                    'fontWeight': 'bold',
                    'fontSize': '15px' 
                }"
                :key="index"
                :position="{lat:41.3898233, lng:2.1728722}"
                :clickable="true"
                :draggable="false"
            />
            </GmapMap>
            </div>
            <div class="col-md-6 pt-3">   
                <h3 class="text-primary">EXTOM GRANADA</h3>
                <p>Calle Bruno, 1</p>
                <p>  18200 Maracena  </p>
                <p><a class="pl-2 pr-2 telf-flotante-num order-2 text-primary" href="tel:958687267"> Tél.: 958 68 72 67</a></p>  
            <GmapMap class="mapa"
                :center="{lat:37.20889071293974, lng:-3.635438982209726}"
                :zoom="14"
            >
            <GmapMarker
                :label="{
                    'text': 'Calle Bruno, 1',
                    'fontWeight': 'bold',
                    'fontSize': '15px' 
                }"
                :key="index"
                :position="{lat:37.20889071293974, lng:-3.635438982209726}"
                :clickable="true"
                :draggable="false"
            />
            </GmapMap>
            </div>
        </div>
    </div>
</template>

<script>

export default {  
    
}
</script>
/* AIzaSyDNSWGU3uQ4A1X4KBUrZR4xB4Nw2JhzmdI */
<style>
.mapas{
    text-align: left;
}
@media (min-width: 700px) { 
    .mapa{
    width:240px;
    height:140px;
    }
}
@media (min-width: 975px) { 
    .mapa{
    width:360px;
    height:210px;
    }
}
@media (min-width: 1184px) { 
    .mapa{
    width:480px;
    height:280px;
    }
}

</style>