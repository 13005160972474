<template>
  <div>
    <div>
      <Toast position="top-right" class="mt-5" style="background-color: rgb(253 247 234)" :breakpoints="{'550px': {width: '90%', 'margin-left': 'auto', 'margin-right': 'auto'}}">
        <template
          #message="slotProps"
          v-if="
            this.visible &&
            (this.cookiesAceptadas == null || this.cookiesAceptadas == 'false')
          "
        >
          <div class="p-d-flex p-flex-column">
            <div class="p-text-center"> 
              <i class="fa fa-cookie-bite" style="font-size: 3rem"></i>
              <h4>{{ slotProps.message.summary }}</h4>
              <p>{{ slotProps.message.detail }}</p>
              <small
                >Puede obtener más información sobre cookies
                <router-link to="/politica-de-privacidad"> aquí </router-link>
              </small>
            </div>
            <div class="p-grid">
              <div class="p-col-8">
                <Button
                  class="p-button-success mr-4"
                  label="Aceptar"
                  @click="aceptarCookies"
                />
                <Button
                  class="bRechazar"
                  label="Rechazar"
                  @click="declinarCookies"
                />
              </div>
            </div>
          </div>
        </template>
      </Toast>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      visible: null,
      cookiesAceptadas: null,
    };
  },
  mounted() {
    this.visible = true;
    this.cookiesAceptadas = this.$cookies.get("cookiesAceptadas");
    if (
      this.visible &&
      (this.cookiesAceptadas == null || this.cookiesAceptadas == "false")
    ) {
      this.$toast.add({
        severity: "",
        summary: "Aviso sobre uso de cookies",
        detail:
          "Pulsando 'aceptar', aceptas todas las cookies propias y de terceros.",
        closable: false,
      });
    }
  },
  methods: {
    declinarCookies() {
      this.visible = false;
      this.cookiesAceptadas = false;
      this.$cookies.set("cookiesAceptadas", false);
      this.$toast.removeAllGroups();
    },
    aceptarCookies() {
      this.visible = false;
      this.cookiesAceptadas = true;
      this.$cookies.set("cookiesAceptadas", true);
      this.$toast.removeAllGroups();
    },
  },
};
</script>

<style scoped>
.bRechazar {
  background-color: red !important;
}
:hover > .bRechazar {
  background-color: rgb(160, 5, 5) !important;
}
.prueva {
  background-color: blue;
}
</style>
