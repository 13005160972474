<template>
  <div>
    <!-- Footer -->
    <footer class="text-center text-lg-start bg-extom-dark text-muted mt-8">
      <!-- Section: Social media -->
      <section
        class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
      >
        <!-- Left -->
        <div class="me-5 d-none d-lg-block">
          <span>Contacte con nosotros en nuestras redes sociales:</span>
        </div>
        <!-- Left -->

        <!-- Right -->
        <div>
          <a
            v-for="red in redes"
            v-bind:key="red.icono"
            :href="red.enlace"
            class="me-4 text-reset"
            target="_blank"
          >
            <i :class="red.icono"></i>
          </a>
        </div>

        <!-- Right -->
      </section>
      <!-- Section: Social media -->

      <!-- Section: Links  -->
      <section class="">
        <div class="container text-center text-md-start mt-5">
          <!-- Grid row -->
          <div class="row mt-3">
            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <!-- Content -->
              <h6 class="text-uppercase fw-bold mb-4">
                <i class="fas fa-gem me-3"></i
                ><span>{{ secciones.compania.titulo }}</span>
              </h6>
              <p>
                {{ secciones.compania.contenido }}
              </p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            
            <!-- Grid column -->

            <!-- Grid column -->
            
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <!-- Links -->
              <h6 class="text-uppercase fw-bold mb-4">Contacto</h6>
              <p
                v-for="contacto in secciones.contacto"
                v-bind:key="contacto.contenido"
              >
                <a target="_blank" style="color:#d1ae51" class="cursor-pointer" :href="contacto.link"><i :class="contacto.icono"></i>{{ contacto.contenido }}</a>
              </p>
            </div>
            <!-- Grid column -->
          </div>
          <!-- Grid row -->
        </div>
      </section>
      <!-- Section: Links  -->

      <!-- Copyright -->
      <div
        class="text-center p-4"
        style="background-color: rgba(0, 0, 0, 0.05)"
      >
        © {{year}} Copyright:
        <a
          class="text-reset fw-bold"
          href="https://www.linkedin.com/company/extomconsulting/?originalSubdomain=es"
          target="_blank"
          >Extom Consulting S.L.</a
        >
      </div>
      <!-- Copyright -->
    </footer>
    <!-- Footer -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "coFooter",
  components: {},
  data: function () {
    return {
      year:2022,
      redes: "",
      secciones: {
        compania:{
          titulo:''
        }
      },
    };
  },
  mounted() {
    this.year = new Date().getFullYear()
    axios.get("json/footer.json").then((response) => {
      this.redes = response.data.redes;
      this.secciones = response.data.secciones;
    });
  },
};
</script>
