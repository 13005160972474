<template>
    <div class="pt-5 pb-5 container mt-lg-5 mb-lg-5 text-left">
        
        <h2>Política de privacidad en Extom</h2>
        <p>Conforme al Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril, relativo a la protección de las personas físicas en lo que se refiere 
            al tratamiento de datos personales y a la libre circulación de los mismos, y a la legislación de protección de datos vigente, Ley 3/2018, de 5 de diciembre, 
            de protección de datos y garantía de los derechos digitales, así como en cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información
            y de Comercio Electrónico (LSSICE), EXTOM CONSULTING, S.L. le informa que es titular del sitio web, y en virtud del art. 10 de la citada Ley, informa 
            de los siguientes datos:</p>
        <h3>Identificación</h3>
        <p>
        Denominación social: EXTOM CONSULTING, S.L. <br> 
        Denominación comercial: Extom <br>
        CIF: {{datosWeb.xcif}}<br>
        Sede social: {{datosWeb.xsede_social}}<br>
        Otras sedes:
        <ul>
            <li>{{datosWeb.xsede_social2}}</li>            
            <li>{{datosWeb.xsede_social3}}</li>      
        </ul>
        <b>

        
        Correo Electrónico: {{datosWeb.xemail}}<br>

        Correo electrónico ejercicio derechos: {{datosWeb.xemail_derechos}}<br>

        Delegado de Protección de Datos (DPO): {{datosWeb.xdelegadoRGPD}}<br>

        Inscrita en el Registro Mercantil de Madrid: {{datosWeb.xinscripcion_mercantil}}<br>

        Página Web: {{datosWeb.xweb}} (En adelante la Web)
        </b>
        </p>

        <h3>Obtención de sus datos</h3>
        <p>
            Extom ha podido obtener sus datos de carácter personal a través de alguna de las siguientes fuentes: <br><br>
            A través de su alta como cliente: datos proporcionados de forma directa por el cliente;<br><br>
            A través de fuentes públicas: datos obtenidos a través de diversas fuentes públicas como administraciones públicas, revistas, tarjetas de visita, colegios profesionales, publicaciones online, páginas web, redes sociales, guías telefónicas etc.;<br><br>
            A través de su solicitud de información a través de la página web y/o de correos electrónicos;<br><br>
            Cuando se registra para usar nuestros sitios web, aplicaciones o servicios (incluidas pruebas gratuitas); aquí pueden incluirse su nombre (incluido el nombre comercial), dirección, dirección de correo electrónico y número de teléfono. También es posible que le solicitemos datos adicionales sobre su actividad profesional y sus preferencias;<br><br>

            Cuando realiza una solicitud a través de nuestros sitios web, aplicaciones o servicios; aquí pueden incluirse su nombre (incluido el nombre comercial), dirección, contacto (como su número de teléfono y dirección de correo electrónico) y datos de pago;<br><br>

            Cuando cumplimenta formularios online (incluidas peticiones de contestación de llamada), participa en encuestas, publica en nuestros tableros de mensajes, publica algún blog, se apunta a concursos o sorteos, descarga información como demos y demás publicaciones o participa en cualquier otra zona interactiva que figure en nuestro sitio web o dentro de nuestra aplicación o servicio;<br><br>

            Cuando interactúa con nosotros a través de las redes sociales;

            Cuando nos indica sus datos de contacto al registrarse para usar o acceder a cualesquiera sitios web, aplicaciones o servicios que pongamos a disposición o cuando actualice dichos datos; y contacta con nosotros por canales no en línea, como por teléfono, SMS, WhatsApp, correo electrónico o correo ordinario.<br><br>

            También podemos recopilar sus datos cuando cumplimenta, aunque sea parcialmente y/o abandona la introducción de información en nuestro sitio web y/u otros formularios online; así, es posible que usemos dichos datos a fin de contactar con usted para recordarle que finalice la cumplimentación de los datos pendientes y/o para finalidades de marketing.<br><br>

            También recopilamos datos a partir de sus dispositivos (incluidos dispositivos móviles) y aplicaciones que usted o sus usuarios utilicen para acceder y hacer uso de cualquiera de nuestros sitios web, aplicaciones o servicios (por ejemplo, podremos recopilar el número y tipo de identificación del dispositivo, datos de localización e información de conexión como estadísticas sobre sus visitas de páginas, el tráfico de entrada y salida de sitios web, URL previa de enlace, datos de anuncios, su dirección IP, su historial de navegación y sus datos de acceso al sitio web); antes de proceder a ello le pediremos permiso. Para ello es posible que hagamos uso de cookies o tecnologías similares (conforme se describen en su apartado correspondiente).<br><br>

            Podemos completar los datos personales que recopilamos de usted con información que se obtenga de terceros habilitados para compartirla, como información de agencias crediticias, proveedores de información de búsqueda o fuentes públicas, etc., pero en cada caso conforme a la legislación aplicable.
        </p>

        <h3>Cesión de datos de terceros</h3>
        <p>Si nos proporciona los datos personales sobre otra persona, usted será responsable de asegurarse de cumplir con todas las obligaciones de consentimiento y de información en relación a la legislación aplicable en materia de protección de datos y en relación con dicha divulgación. En la medida en que lo exige la legislación aplicable en materia de protección de datos, usted debe asegurarse de que ha informado debidamente a la otra persona y de que dispone de su consentimiento explícito para cedernos sus datos y de las finalidades de dichos tratamientos.</p>

        <h3>Legitimación</h3>
        <p>
            La base legal para el tratamiento de sus datos es la ejecución del contrato de prestación de servicios (Acuerdo marco) y cualquier cesión estrictamente necesaria para el cumplimiento de dicho propósito, así como el interés legítimo por ser cliente o por haber solicitado información sobre nuestros productos o servicios con carácter previo. <br><br>
            El envío de comunicaciones comerciales, cesiones de datos a terceros y captación/uso de imágenes estará legitimado en todo momento por el consentimiento expresos otorgado por usted.
        </p>

        <h3>Principios</h3>
        <p>
            En el tratamiento de sus datos personales, aplicaremos los siguientes principios que se ajustan a las exigencias del nuevo reglamento europeo de protección de datos: <br><br>
            <ul>
                <li>Principio de licitud, lealtad y transparencia: Siempre vamos a requerir su consentimiento para el tratamiento de tus datos personales para uno o varios fines específicos que le informaremos previamente con absoluta transparencia.</li><br>
                <li>Principio de minimización de datos: Solo vamos a solicitar datos estrictamente necesarios en relación con los fines para los que los requerimos.</li><br>
                <li>Principio de limitación del plazo de conservación: los datos serán mantenidos durante no más tiempo del necesario para los fines del tratamiento, en función a la finalidad, le informaremos del plazo de conservación correspondiente.</li><br>
                <li>Principio de integridad y confidencialidad: Sus datos serán tratados de tal manera que se garantice una seguridad adecuada de los mismos y se garantice su confidencialidad. Debe saber que tomamos todas las precauciones necesarias para evitar el acceso no autorizado o uso indebido de los datos de nuestros usuarios por parte de terceros.</li><br>
            </ul>
        </p>

        <h3>Finalidad de la recogida y tratamiento de datos personales</h3>
        <p>
            A continuación, se detallan las distintas finalidades de los tratamientos de datos por parte de Extom.
            <ol>
                <li>
                    Prestación de Servicios relativos al Sector Informático. <br><br>
                    Dentro de esta finalidad se engloban las diferentes actividades que resulten necesarias e inherentes a la prestación de los servicios o productos:<br><br>
                    Mantenimiento y Soporte Informático;<br><br>
                    Servicios Cloud;<br><br>
                    Mantenimiento del Software de Gestión;<br><br>
                    Gestión de intervenciones;<br><br>
                    Contactar con usted para ver si desea participar en nuestros estudios sobre el cliente (por ejemplo, su opinión sobre su uso de nuestras aplicaciones, productos y servicios);<br><br>
                    Evaluar, llevar a cabo análisis estadísticos y establecimiento de referencias, siempre y cuando, en tales circunstancias, se haga de forma agregada que no pueda vincularse a usted ni a ninguna otra persona física individualmente;<br><br>
                </li>
                <li>
                    Envío de Información y/o Publicidad de nuestros productos o servicios. <br><br>
                    Dentro de esta finalidad se engloba el uso de sus datos personales para enviarle comunicaciones relacionadas con la prestación, mejora y actualización de nuestros servicios, así como información publicitaria sobre promociones aplicables al producto/servicio, o envío de información relativa a normativas e innovaciones del sector, en base en el uso que usted hace de nuestras aplicaciones y servicios; <br><br>
                    Extom podrá utilizar sus datos de contacto para realizar acciones comerciales y enviarles a través de los canales de comunicación (a título enunciativo, pero no limitativo, mensajes de WhatsApp, correos electrónicos, llamadas telefónicas, etc.) información sobre productos o servicios que puedan resultar de su interés.<br><br>
                    En cualquier momento se permitirá al cliente oponerse al envío de nuevas comunicaciones comerciales pudiendo hacer uso de este derecho a través de la dirección de correo electrónico indicada al respecto.<br><br>
                </li>
                <li>
                    Cesión de sus datos a empresas para la prestación del servicio.<br><br>
                    Extom podrá ceder sus datos a empresas vinculadas con la prestación de servicios y estrictamente necesarias para la ejecución de los productos o servicios contratados por usted como por ejemplo empresas de transporte, otros servicios informáticos, hosting, etc.<br><br>
                </li>
                <li>
                    Comunicar los datos de impago<br><br>
                    En caso de no atender a sus obligaciones económicas, generando una deuda cierta, vencida y exigible y previo requerimiento de pago, se le informa que procederemos a comunicar sus datos identificativos y los datos relativos a la deuda a todas aquellas entidades encargadas del asesoramiento, gestión y tramitación de impagos, de acuerdo con la legislación vigente.<br><br>
                </li>
                <li>
                    Formularios de envío de CV’s:<br><br>
                    La finalidad de este tratamiento será la participación en procesos de selección que Extom tenga abiertos o abra en un futuro. Conservaremos sus datos durante un máximo de dos años salvo que Vd. nos manifieste lo contrario, y en todo caso, durante el tiempo imprescindible para el cumplimiento de las obligaciones legales. <br><br>
                </li>
            </ol>
        </p>

        <h3>Periodo de conservación de sus datos</h3>
        <p>
            Los datos personales proporcionados se conservarán mientras sean necesarios para la prestación de servicios contratados, para dar respuesta a las peticiones o solicitudes realizadas, y en cualquier caso mientras dure la relación contractual entre las partes o durante los años necesarios para el cumplimiento de las obligaciones impuestas por otras normativas de referencia. Así pues, en virtud de la normativa fiscal sus datos se conservarán durante 5 años. <br><br>
            Extom aplicará el protocolo de supresión y borrado de datos conforme a los dispuesto en la normativa de protección de datos en cada momento vigente. En cualquier caso, si al finalizar la relación contractual existieran litigios pendientes, los datos podrán conservarse hasta el momento en el que se produzca la resolución definitiva.
        </p>

        <h3>Destinatarios</h3>
        <p>
            Extom podrá ceder sus datos a empresas para fines administrativos o de cumplimiento legal (auditores, aseguradoras, entidades bancarias, administraciones públicas, etc.) <br><br>
            Extom podrá ceder sus datos a todas aquellas empresas necesarias para la ejecución del servicio contratado o para la reclamación de impagos, entre ellos nuestros proveedores de servicio y agentes (incluidos sus subcontratistas) o terceros que traten datos en nuestro nombre (proveedores de servicios y plataformas de Internet, proveedores de tramitación de pagos y las entidades contratadas para el envío de comunicaciones, entre otros) para que puedan ayudarnos a ofrecerle las aplicaciones, productos, servicios e información que usted haya solicitado o que estimemos que son de su interés;<br><br>
            Extom bajo consentimiento expreso del interesado, podrá ceder sus datos a otras empresas externas para la realización de acciones comerciales de productos o servicios que puedan resultar de su interés.
        </p>

        <h3>Sus derechos</h3>
        <p>
            En cumplimiento con lo dispuesto en el art 22 del Reglamento General de Protección de Datos de la Unión Europea, RGPD 2016/679, usted podrá ejercer cualquiera de los siguientes derechos: <br><br>
            Derecho de acceso: Conocer qué tipo de datos estamos tratando y las características del tratamiento que estamos llevando a cabo.<br><br>
            Derecho de rectificación: Poder solicitar la modificación de sus datos por ser estos inexactos o no veraces.<br><br>
            Derecho de portabilidad: Poder obtener una copia de su información en un formato automatizado y fácil de exportar, de uso común y legible electrónicamente, o a que se le envíen directamente a otra empresa, de ser técnicamente posible. En caso de que se soliciten en un formato que no sea el propio de Extom, podrá tener que afrontar un importe por la elaboración del mismo.<br><br>
            Derecho de limitación: Poder limitar el tratamiento de mis datos según la finalidad especifica.<br><br>
            Derecho de supresión: Solicitar la supresión de sus datos cuando el tratamiento ya no resulte necesario.<br><br>
            Derecho de oposición: Solicitar el cese en el tratamiento de sus datos para una finalidad determinada, como por ejemplo, para fines de marketing directo o cuando este se lleve a cabo en aras de nuestros intereses legítimos<br><br>
            Derecho a oponerse a decisiones que se basen en el tratamiento automatizado de sus datos personales, como la elaboración de perfiles;<br><br>
            De la misma forma, podrá revocar el consentimiento prestado caso de que el tratamiento de sus datos dependa de su consentimiento, siempre sujeto a las restricciones legales o contractuales existentes, y presentar una reclamación ante la AEPD (https://www.aepd.es)<br><br>
            Podrá ejercitar cualquiera de los derechos mencionados anteriormente mediante correo electrónico a la dirección <b>{{datosWeb.xemail_derechos}}</b> , indicando textualmente en el asunto el derecho a ejercer y adjuntando un documento que acredite su identidad. No obstante, podrá también realizar las consultas que considere necesarias a Delegado de Protección de Datos al email: <b>{{datosWeb.xdelegadoRGPD}}</b> <br><br>
        </p>

        <h3>Exactitud y veracidad de los datos</h3>
        <p>
            Como usuario, es el único responsable de la veracidad y corrección de los datos que nos remite y adjunta, exonerando a Extom de cualquier responsabilidad al respecto. <br><br>
            Los usuarios garantizan y responden, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se comprometen a mantenerlos debidamente actualizados. El usuario acepta proporcionar información completa y correcta en el formulario de contacto o suscripción. En caso de que facilite datos de terceros deberá contar con el consentimiento previo de los mismos.
        </p>

        <h3>Confidencialidad y seguridad de los datos</h3>
        <p>
            Extom se compromete en el uso y tratamiento de los datos incluidos personales de los usuarios, respetando su confidencialidad y a utilizarlos de acuerdo con la finalidad del mismo, así como a dar cumplimiento a su obligación de conservarlos y adaptar todas las medidas para evitar la alteración, pérdida, tratamiento o acceso no autorizado, de conformidad con lo establecido en la normativa vigente de protección de datos.<br><br>
            Esta web incluye un certificado SSL. Se trata de un protocolo de seguridad que hace que sus datos viajen de manera íntegra y segura, es decir, la transmisión de los datos entre un servidor y usuario web, y en retroalimentación, es totalmente cifrada o encriptada.<br><br>
            Extom no puede garantizar la absoluta inexpugnabilidad de la red Internet y por tanto la violación de los datos mediante accesos fraudulentos a ellos por parte de terceros, no pudiendo asumir responsabilidad alguna por dichos ataques.<br><br>
            Con respecto a la confidencialidad del procesamiento, Extom se asegurará de que cualquier persona que esté autorizada por Extom para procesar los datos del usuario cliente, estará bajo la obligación de confidencialidad.<br><br>
            En caso de que se presente algún incidente de seguridad, Extom lo notificará al Cliente y usuario sin demoras indebidas y le proporcionará la información oportuna relacionada con el Incidente de Seguridad según se tenga conocimiento, tal como se conozca o cuando el Cliente lo solicite razonablemente.
        </p>

        <h3>Aceptación y consentimiento</h3>
        <p>
            El usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento de los mismos por parte de Extom. en la forma y para las finalidades indicadas en esta Política de Privacidad.
        </p>

        <h3>Revocabilidad</h3>
        <p>
            El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos de los interesados, es revocable en cualquier momento comunicándolo a Extom en los términos establecidos en esta Política para el ejercicio de los derechos. Esta revocación en ningún caso tendrá carácter retroactivo.
        </p>

        <h3>Cambios en la política de privacidad</h3>
        <p>
            Extom se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, Extom anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.
        </p>

        <h3>Correos comerciales</h3>
        <p>
            De acuerdo con la LSSICE, Extom no realiza prácticas de SPAM, por lo que no envía correos comerciales por vía electrónica que no hayan sido previamente solicitados o autorizados por el usuario. En consecuencia, en cada uno de los formularios inseridos en la web, el usuario tiene la posibilidad de dar su consentimiento expreso para recibir comunicaciones comerciales de servicios y productos ofrecidos por Extom, Newsletters, etc., con independencia de la información comercial puntualmente solicitada. <br><br>
            Conforme a lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de comercio electrónico, Extom, se compromete a no enviar comunicaciones de carácter comercial sin identificarlas debidamente.<br><br>
        </p>
    </div>    
</template>

<script>
import ViewService from '../services/view.service'
export default {
    mounted () {
        ViewService.getDatosWeb().then((response)=>{
            this.datosWeb = response.data[0];
        })
    },
    data(){
        return{
            datosWeb:{},
        }
    }
}
</script>

<style scoped>

</style>