<template>
  <Carousel
    :value="heros"
    :numVisible="1"
    :numScroll="1"
    :circular="true"
    indicatorsContentClass=""
    :autoplayInterval="1200000"
    containerClass=""
  >
    <template #item="slotProps">
      <section class="co-hero">
        <div class="grid grid-nogutter surface-0 text-800 align-items-center">
          <div
            class="col-12 col-lg-6 col-xl-4
            pb-6 md:p-6 text-left md:text-left flex align-items-center 
            flex-order-2 md:flex-order-1 "
          >
            <section>
              <span class="block text-3xl md:text-5xl font-bold mb-1">
                {{ slotProps.data.xtitulo }}
              </span>
              <div class="text-3xl md:text-5xl text-primary font-bold mb-3">
                {{ slotProps.data.xtitulo2 }}
              </div>
              <p class="mt-0 mb-4 text-700 text-xl line-height-3">
                {{ slotProps.data.xdescripcion }}
              </p>
              <vs-button
                v-if="slotProps.data.xto"
                label="Saber más"
                class="mr-3 p-button-raised"
                :to="slotProps.data.xto"
                size="large"
                floating
              >
              <span>Saber más</span>
              </vs-button>
            </section>
          </div>
          <div
            v-if="!slotProps.data.xvideo"
            class="col-12 col-lg-6 col-xl-7 overflow-hidden flex-order-1 md:flex-order-2 "
          >
            <img
              :src="slotProps.data.ximg"
              alt="Image hero"
              class="md:ml-auto block md:h-full hero-image"
              @load="imgLoaded"
            />
          </div>
          <!-- <div
            v-if="slotProps.data.video2 == true"
            class="col-12 col-lg-7 overflow-hidden flex-order-1 md:flex-order-2 "
          >
            <video
              v-if="slotProps.data.video2 == true"
              autoplay
              controls
              muted
              loop
              @canplay="imgLoaded"
              id="myVideo"
              class="col-12 ml-auto mr-auto overflow-hidden flex-order-1 md:flex-order-2 "
            >
              <source :src="slotProps.data.videosrc" type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div> -->

          <!-- SI SE PONE EL VIDEO A PANTALLA COMPLETA -->
          <video
            v-if="slotProps.data.xvideo"
            autoplay
            muted
            loop
            @canplay="imgLoaded"
            id="myVideo"
            class="mt-7 mt-lg-0
            order-1 order-lg-2 col-12 col-lg-6 col-xl-8  ml-auto 
            mr-auto overflow-hidden flex-order-1 md:flex-order-2 "
            style="clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 20% 52%);"
          >
            <source :src="slotProps.data.xvideo" type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        </div>
      </section>
    </template>
  </Carousel>
</template>

<script lang="js">
import API_URL from '../backend';
import ViewService from '../services/view.service'
  export default  {
    name: 'co-hero',
    props: [],
    mounted () {
      ViewService.getHeros().then((response)=>{
        this.heros = response.data;
        this.heros.forEach(hero=>{
          hero.ximg = API_URL + hero.ximg;
          if(hero.xvideo != null && hero.xvideo!="null")
            hero.xvideo = API_URL + hero.xvideo;
          else{
            delete hero['xvideo']
          }
        })
      })
    },
    data () {
      return {
        heros:[{},{}],
        imgsLoaded:[],
        videosLoaded:[],

      }
    },
    methods: {
      imgLoaded(){
        this.imgsLoaded.push(true);
        if(this.imgsLoaded.length == this.heros.length){
          this.$emit('loaded', true);
        }
      }
    },
    computed: {

    }
}
</script>

<style lang="scss">
.p-carousel-items-container {
  align-items: start;
  /* align-items: center; */
}

.texto-video {
  position: relative;
  bottom: 0;
  /* top: 56%;
  left: 101.58vw;
  right: 100vh; */
  background-color: #ffffffad;
  @media (min-width: 992px) {
    position: fixed;
  }
  @media (min-width: 1400px) {
    left: 112.46%;
  }
}
.co-hero {
  background-color: #fffcf6 !important;
}
.bg-extom {
  background-color: #d99d0446 !important;
}
.bg-extom-dark {
  background-color: #d99d0418;
}
.p-carousel-item {
  width: 100%;
}
.hero-image {
  height: 50vh;
}
@media (min-width: 768px) {
  .hero-image {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 20% 52%);
    max-height: 65vh;
  }
}
</style>
