<template lang="html">
  <section class="v-back-office">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <transition name="route" mode="out-in">
            <router-view :key="$route.path" />
          </transition>
          <div v-if="dashboardRoute" class="d-flex flex-wrap mt-3">
            <vs-card
              type="2"
              class="col-12 col-md-6 col-lg-3"
              @click="$router.push({ path: '/admin/dashboard/perfiles' })"
            >
              <template #img>
                <div
                  class="p-3 text-center bg-blue-500 w-100"
                  style="border-radius: 12px"
                >
                  <span
                    class="
                      inline-flex
                      justify-content-center
                      align-items-center
                      bg-blue-600
                      border-circle
                      mb-3
                    "
                    style="width: 49px; height: 49px"
                    ><i class="pi pi-id-card text-xl text-white"></i
                  ></span>
                  <div class="text-2xl font-medium text-white mb-2">
                    {{ totalVisitasPerfiles }}
                  </div>
                  <span class="text-blue-100 font-medium"
                    >Visitas a todos los perfiles</span
                  >
                </div>
              </template>
            </vs-card>
            <vs-card
              type="2"
              class="col-12 col-md-6 col-lg-3"
              @click="$router.push({ path: '/admin/dashboard/servicios' })"
            >
              <template #img>
                <div
                  class="p-3 text-center bg-purple-500 w-100"
                  style="border-radius: 12px"
                >
                  <span
                    class="
                      inline-flex
                      justify-content-center
                      align-items-center
                      bg-purple-600
                      border-circle
                      mb-3
                    "
                    style="width: 49px; height: 49px"
                    ><i class="pi pi-th-large text-xl text-white"></i
                  ></span>
                  <div class="text-2xl font-medium text-white mb-2">
                    {{ totalVisitasServicios }}
                  </div>
                  <span class="text-purple-100 font-medium"
                    >Visitas a todos los servicios</span
                  >
                </div>
              </template>
            </vs-card>

            <vs-card
              type="2"
              class="col-12 col-md-6 col-lg-3"
              @click="$router.push({ path: '/admin/dashboard/perfiles' })"
            >
              <template #img>
                <div
                  class="p-3 text-center bg-indigo-500 w-100"
                  style="border-radius: 12px"
                >
                  <span
                    class="
                      inline-flex
                      justify-content-center
                      align-items-center
                      bg-indigo-600
                      border-circle
                      mb-3
                    "
                    style="width: 49px; height: 49px"
                    ><i
                      class="text-xl text-white"
                      :class="perfilMasVisitado.xicon"
                    ></i
                  ></span>
                  <div class="text-2xl font-medium text-white mb-2">
                    {{ perfilMasVisitado.xvisitas }}
                  </div>
                  <span class="text-indigo-100 font-medium">{{
                    perfilMasVisitado.xtitulo
                  }}</span>
                </div>
              </template>
            </vs-card>

            <vs-card
              type="2"
              class="col-12 col-md-6 col-lg-3"
              @click="$router.push({ path: '/admin/dashboard/servicios' })"
            >
              <template #img>
                <div
                  class="p-3 text-center bg-orange-500 w-100"
                  style="border-radius: 12px"
                >
                  <span
                    class="
                      inline-flex
                      justify-content-center
                      align-items-center
                      bg-orange-600
                      border-circle
                      mb-3
                    "
                    style="width: 49px; height: 49px"
                    ><img
                      class="text-xl text-white"
                      :class="servicioMasVisitado.xicon"
                      :src="servicioMasVisitado.ximg"
                  /></span>
                  <div class="text-2xl font-medium text-white mb-2">
                    {{ servicioMasVisitado.xvisitas }}
                  </div>
                  <span class="text-orange-100 font-medium">{{
                    servicioMasVisitado.xtitulo
                  }}</span>
                </div>
              </template>
            </vs-card>
          </div>
          <div v-if="dashboardRoute" class="d-flex flex-wrap justify-content-evenly">
            <div
              class="
                mt-4
                shadow-2
                surface-card
                border-round
                p-8
                col-12 col-lg-6
              "
            >
            <h5 class="mb-3">Visitas Perfiles/Servicios</h5>
              <Chart ref="chart" type="pie" :data="chartData" :options="chartOptions" />
            </div>
            <div
              class="
                mt-4
                shadow-2
                surface-card
                border-round
                p-3
                col-12 col-lg-5
              "
            >
              <div class="flex align-items-center justify-content-between p-2">
                <span class="ml-auto mr-auto text-2xl font-medium text-900"
                  >Redes sociales</span
                >
              </div>
              <div class="mt-3">
                <div class="grid p-4">
                  <a
                    class="col-12 md:col-6"
                    href="https://www.linkedin.com/company/extomconsulting/mycompany/"
                    target="_blank"
                  >
                    <div
                      class="
                        text-center
                        border-1
                        surface-border
                        border-round
                        p-4
                      "
                    >
                      <i
                        class="pi pi-linkedin text-5xl"
                        style="color: #0e76a8"
                      ></i>
                      <div class="text-900 text-2xl font-700 my-3 font-bold">
                        Linkedin
                      </div>
                    </div>
                  </a>
                  <a
                    class="col-12 md:col-6"
                    href="https://twitter.com/EXTOMconsulting"
                    target="_blank"
                  >
                    <div
                      class="
                        text-center
                        border-1
                        surface-border
                        border-round
                        p-4
                      "
                    >
                      <i class="pi pi-twitter text-5xl text-blue-500"></i>
                      <div class="text-900 text-2xl font-700 my-3 font-bold">
                        Twitter
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <coSidebar />
  </section>
</template>

<script lang="js">
import API_URL from"../backend.js"
import coSidebar from '../components/BackOffice/coSidebar'
import EstadisticasService from '../services/estadisticas.service.js'

  export default  {
    name: 'v-back-office',
    props: [],
    mounted () {
      EstadisticasService.servicioMasVisitado().then((response)=>{
        this.servicioMasVisitado = response.data;
        this.servicioMasVisitado.ximg = API_URL+this.servicioMasVisitado.ximg
      }).catch((error)=>{
        console.error(error)
      })


      EstadisticasService.perfilMasVisitado().then((response)=>{
        this.perfilMasVisitado = response.data;
      }).catch((error)=>{
        console.error(error)
      })


      EstadisticasService.visitasPerfiles().then((response)=>{
          this.totalVisitasPerfiles = response.data.total;
      }).catch((error)=>{
        console.error(error)
      })


      EstadisticasService.visitasServicios().then((response)=>{
        this.totalVisitasServicios = response.data.total;
      }).catch((error)=>{
        console.error(error)
      })

      EstadisticasService.todasVisitasPorServicioPerfil().then((response)=>{
        const labels =[];
        const data = [];
        const bgcolor=[];
        const hoverbgcolor=[];
        for(let i = 0; i<response.data.length; i++){
          let color = this.generarNuevoColor();
          labels.push(response.data[i].xtitulo)
          data.push(response.data[i].xvisitas)
          bgcolor.push(color)
          hoverbgcolor.push(color)
        }
        this.chartData.labels = labels;

        this.chartData.datasets[0].data = data;
        this.chartData.datasets[0].backgroundColor = bgcolor;
        this.chartData.datasets[0].hoverBackgroundColor = hoverbgcolor;
        
        this.$refs.chart.reinit()
        this.$refs.chart.refresh()
      }).catch((error)=>{
        console.error(error)
      })
    },
    components:{
      coSidebar
    },
    data () {
      return {
        imgSRC: API_URL+'/imagenes/extom/horizontal_on_white_by_logaster.jpeg',
        active:'home',
        totalVisitasPerfiles:0,
        totalVisitasServicios:0,
        perfilMasVisitado:{},
        servicioMasVisitado:{},
        chartData:{
          labels: ['A','B','C'],
          datasets: [
            {
              data: [300, 50, 100],
              backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#FFA726"
              ],
              hoverBackgroundColor: [
                "#64B5F6",
                "#81C784",
                "#FFB74D"
              ]
            }
          ]
        },
        chartOptions: {
          responsive: true,
          legend: {
            labels: {
              fontColor: '#495057'
            }
          },
          title: {
            display: true,
            text: 'Visitas Perfiles/Servicios'
          }
        }

      }
    },
    methods: {
      generarNuevoColor(){
        var simbolos, color;
        simbolos = "0123456789ABCDEF";
        color = "#";

        for(var i = 0; i < 6; i++){
          color = color + simbolos[Math.floor(Math.random() * 16)];
        }
        return color;
      }

    },
    computed: {
      dashboardRoute(){
        return this.$route.fullPath.split('/')[2] == "dashboard" && this.$route.fullPath.split('/').length == 3
    }
    }
}
</script>

<style lang="scss">
.v-back-office {
  margin-left: 50px;
  section:nth-last-of-type(2) {
    display: none;
  }
}
</style>
