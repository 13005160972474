<template>
    <div>
        <coPrivacidad/>
    </div>
</template>

<script>
import coPrivacidad from "../components/coPrivacidad.vue";
export default {
   components: {
   coPrivacidad:coPrivacidad
   }
}
</script>

<style>

</style>