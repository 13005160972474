<template >
    <div class="contacto pt-5 pb-5 container mt-lg-5 mb-lg-5 "> 
        <div class="row">
            <div class="col-md-5 "> 
                <h1 class="text-40 text-md-48 mb-4"><strong class="text-primary">¡Pruébanos!</strong> Nos encanta mostrar cómo funciona</h1>
                <p class="text-20">Un asesor especializado contactará contigo próximamente, para conocer tus necesidades y poder agendar una demo.</p>
                <p class="text-20">Nuestras demos no son estándar. Te mostramos cómo se adaptan nuestras soluciones a tus necesidades reales.</p>
                <img class="w-100 mt-4" src="../../public/images/contacto.jpg" alt="Jóven de telemarketing sonriendo">   
            </div> 
            <!-- COLUMNA DE SEPARACIÓN -->
            <div class="col-md-1">

            </div>
            <!-- RESULTADO FORMULARIO CORRECTO -->
            <div v-if="isFormValid" class="col-md-6">
                <strong >¡Muchas gracias por su mensaje! </strong> 
                <p> Un asesor especializado contactará contigo próximamente para conocer tus necesidades y poder agendar una demo.</p>                
            </div>
            <div v-if="!isFormValid" class="col-md-6">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-6">
                        <label for="nombre">Nombre*</label>
                        <InputText v-model="form.nombre" @blur="validarNombre" id="nombre" type="text" />                       
                        <small v-if="!isNomValid&&isNomValid!=null">El valor del campo no es válido</small> 
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="apellidos">Apellidos*</label>
                        <InputText v-model="form.apellidos" @blur="validarApellidos" id="apellidos" type="text" />
                        <small v-if="!isApeValid&&isApeValid!=null">El valor del campo no es válido</small> 
                    </div>   
                    <div class="p-field p-col-12 p-md-6">
                        <label for="correo">Correo electrónico*</label>
                        <InputText v-model="form.email" @blur="validarEmail" id="correo" type="text" />
                        <small v-if="!isMailValid&&isMailValid!=null">El valor del campo no es válido</small>
                    </div>                 
                    <div class="p-field p-col-12 p-md-6">
                        <label for="telefono">Teléfono*</label>
                        <InputText v-model="form.telefono" @blur="validarTelefono" id="telefono" type="text" />
                        <small v-if="!isTelValid&&isTelValid!=null">El valor del campo no es válido</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="compania">Compañía*</label>
                        <InputText v-model="form.compania" @blur="validarCompania" id="compania" type="text" />
                        <small v-if="!isCompValid&&isCompValid!=null">El valor del campo no es válido</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="cargo">Cargo*</label>
                        <Dropdown inputId="cargo" @input="validarCargo" v-model="cargoSeleccionado" :options="info.cargos" placeholder="Seleccione" />
                        <small v-if="!isCargoValid&&isCargoValid!=null">Seleccione una opción</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="sector">Sector*</label>
                        <Dropdown inputId="sector" @input="validarSector" v-model="sectorSeleccionado" :options="info.sectores" placeholder="Seleccione" />
                        <small v-if="!isSectorValid&&isSectorValid!=null">Seleccione una opción</small>
                    </div>
                    <div class="p-field p-col-12 p-md-6">
                        <label for="pais">País*</label>
                        <Dropdown inputId="pais" @input="validarPais" v-model="paisSeleccionado" :options="info.paises" optionValue="name_es" optionLabel="name_es" placeholder="Seleccione" />
                        <small v-if="!isPaisValid&&isPaisValid!=null">Seleccione una opción</small>
                        
                    </div>
                    <br>
                    <div class="p-field p-col-12">
                        <label for="mensaje">Mensaje*</label>
                        <Textarea v-model="form.mensaje" @blur="validarMensaje" id="mensaje" rows="4" />
                        <small v-if="!isMensajeValid&&isMensajeValid!=null">El mensaje debe tener entre 30 y 500 caracteres</small>
                    </div>
                    <br>
                    <div class="p-field-checkbox ">
                        <Checkbox id="terms" v-model="terminos" :binary="true"/>
                        <label id="termsLabel" for="terms"> Pulsando el botón "Enviar" manifiestas que has leído y estás de acuerdo con la <router-link to="politica-de-privacidad">Política de Privacidad</router-link> <a href="" target="_blank"></a> 
                            y el almacenamiento y procesamiento de tus datos personales por parte de Extom, tal y como describe la Información 
                            respecto tus datos personales.*</label>
                            <br>
                        <small v-if="!terminos&&terminos!=null">Debe aceptar los términos de servicio</small>    
                    </div>
                    <br>
                    <div class="p-field-checkbox p-col-12 p-md-6">
                        <Checkbox id="spam" v-model="spam" :binary="true"/>
                        <label for="spam"> Sí, quiero recibir más comunicaciones de Extom, respecto a productos, servicios, eventos, noticias y otro contenido premium.</label>
                    </div>  
                    
                </div>
                <br>
                <Button label="Enviar" @click="validarForm"/>
            </div>  
        </div>  
            
        
    </div>
    <!-- DEBUG                
            {{form.nombre}}isNomValid: {{isNomValid}}<br>
            {{form.apellidos}}isApeValid: {{isApeValid}} <br>
            {{form.email}}isMailValid: {{isMailValid}}<br>
            {{form.telefono}}isTelValid:{{isTelValid}}<br>
            {{form.compania}}isCompValid:{{isCompValid}}<br>
            {{cargoSeleccionado}}isCargoValid:{{isCargoValid}}<br>
            {{sectorSeleccionado}}isSectorValid:{{isSectorValid}}<br>
            {{paisSeleccionado}}isPaisValid:{{isPaisValid}}  <br> 
            {{form.mensaje}} isVladi:{{isMensajeValid}} <br>  DEBUG -->  
</template>
<script>
import axios from 'axios';
export default {
    data: function () {
        return {
            paisSeleccionado:null,
            sectorSeleccionado:null,
            cargoSeleccionado:null,
            info : null,
            isNomValid:null,
            isApeValid:null,
            isMailValid:null,
            isTelValid:null,
            isCompValid:null,
            isCargoValid:null,
            isSectorValid:null,
            isPaisValid:null,
            isMensajeValid:null,
            isFormValid:null,
            terminos:null,
            spam:'',
            
            form:{
                nombre:'',
                apellidos:'',
                email:'',
                telefono:'',
                compania:'',
                mensaje:''
            }
        }
    },    
    mounted(){
      axios.get('json/contact.json').then((response)=>{
        this.info = response.data;
        })
      },
    methods: {
        validarNombre() {
            if (this.form.nombre.length <=1 || this.form.nombre.length >=45) {
                this.isNomValid=false
            }
            else{
                this.isNomValid=true
            }
        },
        validarApellidos() {
            if (this.form.apellidos.length<=1 || this.form.apellidos.length >=60) {
                this.isApeValid=false
            }
            else{
                this.isApeValid=true
            }
        },
        validarEmail() {
            if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.form.email)) {
                this.isMailValid=false
            }
            else{
                this.isMailValid=true
            }
        },
        validarTelefono() {
            if (this.form.telefono.length <9 || !/[0-9]{9}$/.test(this.form.telefono.replaceAll(" ",""))) {
                this.isTelValid=false
            }
            else{
                this.isTelValid=true
            }
        },
        validarCompania() {
            if (this.form.compania.length<=1 || this.form.compania.length >=60) {
                this.isCompValid=false
            }
            else{
                this.isCompValid=true
            }
        },
        validarCargo(){
            if(this.cargoSeleccionado!=null) {
                this.isCargoValid=true
            }
            else{
                this.isCargoValid=false
            }
        },
        validarSector(){
            if(this.sectorSeleccionado!=null) {
                this.isSectorValid=true
            }
            else{
                this.isSectorValid=false
            }
        },
        validarPais(){
            if(this.paisSeleccionado!=null) {
                this.isPaisValid=true
            }
            else{
                this.isPaisValid=false
            }
        },
        validarMensaje(){
            if (this.form.mensaje.length<=30 || this.form.mensaje.length>=500 ) {
                this.isMensajeValid=false
            }
            else{
                this.isMensajeValid=true
            }
        },
        validarTerminos(){
            if(this.terminos==null){
                this.terminos=false
            }
            return this.terminos
        },
        validarForm(){
            this.validarNombre();
            this.validarApellidos();
            this.validarEmail();
            this.validarTelefono();
            this.validarCompania();
            this.validarCargo();
            this.validarSector();
            this.validarPais();
            this.validarMensaje();  
            this.validarTerminos();         

            if(this.isNomValid&&this.isApeValid&&this.isMailValid&&this.isTelValid
            &&this.isCompValid&&this.isCargoValid&&this.isSectorValid&&this.isPaisValid
            &&this.isMensajeValid&&this.terminos) {
                this.isFormValid=true
            }
            else{
                this.isFormValid=false
            }

        }
      }
    }
</script>
<style lang="scss" scoped>
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577;
  border-color: #f8b505;
}
.p-input-icon-left {
  width: none !important;
  @media (min-width: 568px) {
    width: inherit !important;
  }
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    color: #ffffff;
    background: #f8b505 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30575;
  border-color: #f8b505;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #f8b505;
  background: #f8b505;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #d4a52d;
  background: #d4a52d;
  color: #ffffff;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30580;
  border-color: #f8b505;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #f8b305d5;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #f8b30580;
}
.p-invalid .p-fileupload-content {
  border: 1px solid;
  border-color: #dc3545;
}
.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #f8b30577;
    border-color: #f8b505;
}

label{
    display: inline;
}
small{
    color: red;
}

.contacto{
    text-align: left;
    }
@media screen and (max-width: 489px) {
    .p-formgroup-inline {
        .p-field {
            margin-bottom: 1em !important;
        }
    }
    Button{
        align-self: center;
    }
}

.layout-content .content-section.implementation > h3 {
    font-weight: 600;
}

textarea {
    resize: none;
}
</style>
