import { render, staticRenderFns } from "./coContact.vue?vue&type=template&id=60e4523b&scoped=true&"
import script from "./coContact.vue?vue&type=script&lang=js&"
export * from "./coContact.vue?vue&type=script&lang=js&"
import style0 from "./coContact.vue?vue&type=style&index=0&id=60e4523b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e4523b",
  null
  
)

export default component.exports