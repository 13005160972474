import axios from 'axios';
import API_URL from '../backend'

class ViewService {
    getDatosWeb(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/datosWeb',
        }
        return axios(axiosRequest);
    }
    getNavbar(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/navbar',
        }
        return axios(axiosRequest);
    }
    getNavbarHijos(superId){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/navbarHijos/'+superId,
        }
        return axios(axiosRequest);
    }
    getHeros(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/heros',
        }
        return axios(axiosRequest);
    }
    getServicios(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/servicios',
        }
        return axios(axiosRequest);
    }
    getServiciosBlocks(id){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/servicios/'+id,
        }
        return axios(axiosRequest); 
    }
    getPerfiles(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/perfiles',
        }
        return axios(axiosRequest);
    }
    getPerfilesBlocks(id){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/perfiles/'+id,
        }
        return axios(axiosRequest); 
    }
    getEkonBannerHome(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/ekonhome',
        }
        return axios(axiosRequest);
    }
    getMarcas(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/views/marcas',
        }
        return axios(axiosRequest);
    }
}
export default new ViewService();