<template lang="html">
  <div class="v-home-heros p-2">
    <h3 class="text-left">Heros de la página principal</h3>
    <div class="d-flex flex-wrap mt-4">
      <Toolbar class="p-mb-4 col-12">
        <template #left>
          <Button
            label="Nuevo"
            icon="pi pi-plus"
            class="p-button-success p-mr-2"
            @click="
              editHero({
                ximg: '',
                xtitulo: '',
                xtitulo2: '',
                xdescripcion: '',
                xto: '',
                xvisible: false,
                xvideo: '',
              })
            "
          />
          <Button
            label="Borrar"
            icon="pi pi-trash"
            class="p-button-danger ml-2"
            :disabled="!selectedHeros || !selectedHeros.length"
            @click="confirmDeleteHeros"
          />
        </template>
        <template #right> </template>
      </Toolbar>
      <DataTable
        :value="heros"
        :paginator="true"
        class="w-100 p-datatable-customers"
        :rows="10"
        :resizableColumns="true"
        columnResizeMode="fit"
        dataKey="xheroid"
        :rowHover="true"
        :selection.sync="selectedHeros"
        :filters="filters"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 25, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        <template #header>
          <div class="table-header">
            Lista de Heros
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global']"
                placeholder="Búsqueda global"
              />
            </span>
          </div>
        </template>
        <template #empty> No heros found. </template>
        <template #loading> Loading heros data. Please wait. </template>
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="xheroid" header="Id" :sortable="true">
          <template #body="slotProps">
            <span class="p-column-title">Id</span>
            {{ slotProps.data.xheroid }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xheroid']"
              class="p-column-filter"
              placeholder="ID"
              style="width: 5em !important"
            />
          </template>
        </Column>

        <Column field="ximg" header="Img">
          <template #body="slotProps">
            <span class="p-column-title">Imagen</span>
            <img
              style="max-height: 100px"
              :src="slotProps.data.ximg"
              v-if="slotProps.data.ximg"
              @error="imagenError($event, slotProps)"
            />
            <video
              v-if="slotProps.data.xvideo"
              :src="slotProps.data.xvideo"
              class="w-100"
            >
            </video>
          </template>
        </Column>
        <Column
          header="Título"
          :sortable="true"
          sortField="xtitulo"
          filterField="xtitulo"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Título</span>
            {{ slotProps.data.xtitulo }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xtitulo']"
              class="p-column-filter"
              placeholder="Título"
            />
          </template>
        </Column>
        <Column
          header="Título 2"
          :sortable="true"
          sortField="xtitulo2"
          filterField="xtitulo2"
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            <span class="p-column-title">Título 2</span>
            {{ slotProps.data.xtitulo2 }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xtitulo2']"
              class="p-column-filter"
              placeholder="Título2"
            />
          </template>
        </Column>
        <Column
          header="Enlace"
          :sortable="true"
          sortField="xto"
          filterField="xto"
          filterMatchMode="in"
        >
          <template #body="slotProps">
            <span class="p-column-title">Enlace</span>
            {{ slotProps.data.xto }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['xto']"
              class="p-column-filter"
              placeholder="Enlace"
            />
          </template>
        </Column>
        <Column
          field="xfecha_creacion"
          header="Fecha creación"
          sortField="xfecha_creacion"
          :sortable="true"
        >
          <template #body="slotProps">
            <span class="p-column-title">Fecha creación</span>
            <span>{{ slotProps.data.xfecha_creacion.split("T")[0] }}</span>
          </template>
        </Column>
        <Column
          field="xorden"
          header="Orden"
          sortField="xorden"
          :sortable="true"
        >
          <template #body="slotProps">
            <span class="p-column-title">Orden</span>
            <span>{{ slotProps.data.xorden }}</span>
          </template>
        </Column>
        <Column
          field="xvisible"
          header="Visible"
          filterMatchMode="custom"
          :filterFunction="filterVisibles"
          :sortable="true"
        >
          <template #body="slotProps">
            <span class="p-column-title">Visible</span>
            <vs-switch
              v-model="slotProps.data.xvisible"
              class="w-50"
              disabled
            />
          </template>
        </Column>
        <Column
          headerStyle="width: 8rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="slotProps">
            <Button
              type="button"
              icon="pi pi-cog"
              class="p-button-secondary"
              @click="editHero(slotProps.data)"
            ></Button>
            <Button
              type="button"
              icon="pi pi-trash"
              class="p-button-danger ml-2"
              @click="confirmDeleteHero(slotProps.data)"
            ></Button>
          </template>
        </Column>
      </DataTable>

      <Dialog
        :visible.sync="deleteHerosDialog"
        :style="{ width: '450px' }"
        header="Confirmar"
        :modal="true"
      >
        <div class="d-flex flex-column">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> ¿Seguro que quieres borrar estos heros? </span>
          <ul class="text-left ml-auto mr-auto">
            <li v-for="hero in selectedHeros" :key="hero.xheroid">
              Id: {{ hero.xheroid }}
            </li>
          </ul>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text text-white"
            @click="deleteHerosDialog = false"
          />
          <Button
            label="Sí"
            icon="pi pi-check"
            class="p-button-danger"
            @click="deleteHeros"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="heroDialog"
        header="Hero Detalle"
        :modal="true"
        class="p-fluid col-11 col-lg-8"
      >
        <div class="d-flex flex-wrap justify-content-center">
          <vs-card type="4" class="col-12 d-flex justify-content-center">
            <template #img>
              <img
                :src="hero.ximg"
                @error="imagenError($event, hero)"
                class="product-image"
                style="max-height: 300px"
                v-if="hero.ximg || (!hero.ximg && !hero.xvideo)"
                alt=""
              />
              <div
                v-if="hero.xvideo && hero.xvideo != 'null'"
                style="max-height: 300px"
              >
                <video
                  :src="hero.xvideo"
                  v-if="hero.xvideo && hero.xvideo != 'null'"
                  class="product-image w-100"
                  controls
                ></video>
              </div>
            </template>
            <template #interactions>
              <vs-button icon floating @click="uploadImg">
                <i class="pi pi-images"></i>
              </vs-button>
              <vs-button icon floating @click="uploadVideo">
                <i class="pi pi-video"></i>
              </vs-button>
            </template>
          </vs-card>
          <input
            type="file"
            hidden
            accept="image/*"
            size="2000000"
            @change="cambiarImg"
            ref="uploadImg"
          />
          <input
            type="file"
            hidden
            accept="video/*"
            size="20000000"
            @change="cambiarVideo"
            ref="uploadVideo"
          />

          <div class="p-field col-12 col-lg-6">
            <label for="titulo">Título</label>
            <InputText
              id="titulo"
              v-model.trim="hero.xtitulo"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !hero.xtitulo }"
            />
            <small class="p-invalid" v-if="submitted && !hero.xtitulo"
              >Título is required.</small
            >
          </div>
          <div class="p-field col-12 col-lg-6">
            <label for="description">Título 2 (Color extom)</label>
            <InputText id="titulo2" v-model.trim="hero.xtitulo2" />
          </div>
          <div class="col-12 col-lg-10">
            <Textarea v-model="hero.xdescripcion" class="w-100" />
          </div>

          <div class="p-field col-12 col-lg-4">
            <label for="description">Enlace</label>
            <InputText id="enlace" v-model.trim="hero.xto" placeholder="/" />
          </div>
          <div class="p-field col-12 col-lg-4">
            <label for="orden">Orden</label>
            <InputNumber showButtons id="enlace" v-model.trim="hero.xorden" />
          </div>
          <div class="p-field col-12 col-lg-4">
            <label for="description">Visible</label>
            <vs-switch
              v-model="hero.xvisible"
              class="ml-auto mr-auto"
              style="max-width: 50px"
            />
          </div>
        </div>

        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button"
            @click="hideDialog"
          />
          <Button
            label="Save"
            icon="pi pi-check"
            class="p-button"
            @click="saveHero"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script lang="js">
import API_URL from"../../backend.js"
import HerosService from '../../services/heros.service'
import ViewService from "../../services/view.service.js"
  export default  {
    name: 'v-home-heros',
    props: [],
    mounted () {
      this.cargarHeros()
    },
    data () {
      return {
        loader:null,
        heros:[],
        selectedHeros:[],
        filters: {},
        hero:{},
        deleteHerosDialog:false,
        heroDialog:false,
        submitted:false,
        counterDanger: false

      }
    },
    methods: {
      imagenError(event){
        event.target.src = "/images/fondo_gris.jpg"
      },
      cargarHeros(){
        ViewService.getDatosWeb().then((response)=>{
          const logo = response.data[0].xlogo;
          this.loader = this.$vs.loading({
          type: "square",
          color: "#f8b505",
        });
        setTimeout(() => {
          let loaderDIV = document.getElementsByClassName(
            "vs-loading__load__animation"
          )[0];
          loaderDIV.innerHTML = "";
          const imgLogo = document.createElement("img");
          imgLogo.src = API_URL+logo;
          imgLogo.style = "width:200px; z-index: 99999999; border-radius:10px; transition: width 0.4s ease-in-out";
          
          loaderDIV.addEventListener("mouseover", (e) => {
            e.target.style.width="300px";
          })
          loaderDIV.addEventListener("mouseout", (e) => {
            e.target.style.width="200px";
          })
          loaderDIV.appendChild(imgLogo);
        }, 5);
        });


        HerosService.getTodos().then((response) => {
          this.heros = response.data
          this.heros.forEach((hero) => {
            console.log(hero)
            console.log(hero.ximg)
            if(hero.ximg){
              hero.ximg= API_URL+hero.ximg
            }     
            if(hero.xvideo){
              hero.xvideo= API_URL+hero.xvideo
            }       
            hero.xvisible = hero.xvisible == 1 ? true : false
          })
          this.loader.close()
        }).catch((err) => {
          console.log(err)
          this.loader.close()
        })
      },
      filterVisibles(value, filter){
        if(filter === null ){
        return false;
        }else return true
      },
      filterDate(value, filter) {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }
        if (value === undefined || value === null) { return false; }
        return value === this.formatDate(filter);
      },
      confirmDeleteHero(hero) {
        this.selectedHeros = [hero]
        this.deleteHerosDialog = true;
      },
      confirmDeleteHeros() {
        this.deleteHerosDialog = true;
      },
      deleteHeros(){
        let promesasDelete=[];
        for(let i = 0; i<this.selectedHeros.length; i++) {
          promesasDelete.push(HerosService.borrarHero(this.selectedHeros[i].xheroid))
        }
        Promise.all(promesasDelete).then((res) => {
          res.forEach((response) => {
            if(response.data){
              this.$toast.add({
                severity: response.data.success ? 'info' : 'error',
                summary: 'Borrado',
                detail: response.data.description,
                life: response.data.success ? 4000 : null
              })
            }
          })
          this.cargarHeros();
          this.deleteHerosDialog = false;
        })
      },
      editHero(hero) {
        this.hero = {...hero};
        console.log(this.hero);
        this.heroDialog = true;
      },
      hideDialog() {
        this.heroDialog = false;
        this.submitted = false;
        this.hero = {}
      },
      saveHero(){
        const formData = new FormData()
        formData.append("xtitulo", this.hero.xtitulo)
        formData.append("xtitulo2", this.hero.xtitulo2)
        formData.append("xdescripcion", this.hero.xdescripcion)
        formData.append("xorden", this.hero.xorden)
        if(this.hero.ximg)
          formData.append("ximg", this.hero.ximg.replace(API_URL,""))
        formData.append("xvideo", this.hero.xvideo)
        formData.append("xvisible", this.hero.xvisible)
        formData.append("xto", this.hero.xto)
        if(this.hero.xheroid) {
          formData.append("xheroid", this.hero.xheroid)
        }
        if(this.hero.subirVideo) {
          if(this.hero.subirVideo)
          formData.append("subirVideo", this.hero.subirVideo)
        }
        if(this.hero.subirImg) formData.append("subirImg", this.hero.subirImg)

        if(this.hero.xheroid){
          //update
          HerosService.actualizarHero(formData).then((response)=>{
            this.$toast.add({
              severity:response.data.success ? 'info' : 'error',
              summary:response.data.success ? 'Hero actualizado' : 'Error',
              detail:response.data.description,
              life:response.data.success ? 3000 : null
            })
            this.cargarHeros()
            this.heroDialog = false;
          }).catch((error)=>{
            console.log(error)
            this.$toast.add({
              severity:'error',
              summary:'Error',
              detail:error
            })
          });
        }else{
          //insert
          HerosService.nuevoHero(formData).then((response)=>{
            this.$toast.add({
              severity:response.data.success ? 'info' : 'error',
              summary:response.data.success ? 'Hero creado' : 'Error',
              detail:response.data.description,
              life:response.data.success ? 3000 : null
            })
            this.cargarHeros();
            this.heroDialog = false;
          }).catch((error)=>{
            console.log(error)
            this.$toast.add({
              severity:'error',
              summary:'Error',
              detail:error
            })
          });
        }

      },
      uploadVideo(){
        this.$refs.uploadVideo.click();
      },
      uploadImg(){
        this.$refs.uploadImg.click();
      },
      cambiarVideo(){
        const [file] = this.$refs.uploadVideo.files;
        if(file.size>50000000){//50MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'El video no puede pesar más de 50MB',
            life:10000
          });
          return;
        }
        if(!file.type.includes("video/")){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'El archivo debe ser de tipo video',
            life:10000
          })
          return;
        }

        this.hero.xvideo = URL.createObjectURL(file);
        this.hero.subirVideo = file;
        this.hero.ximg = null;
        this.hero.subirImg = null;
      },
      cambiarImg(){
        const [file] = this.$refs.uploadImg.files;
        if(file.size>10000000){//10MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'La imagen no puede pesar más de 10MB',
            life:10000
          })
          return;
        }
        if(!file.type.includes("image/")){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'El archivo debe ser de tipo imagen',
            life:10000
          })
          return;
        }
        
        this.hero.ximg = URL.createObjectURL(file);
        this.hero.subirImg = file;
        this.hero.subirVideo = null;
        this.hero.xvideo = null;

      }

    },
    computed: {

    }
}
</script>

<style lang="scss">
.v-home-heros {
}
.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #ffffff !important;
  background: #d32f2f !important;
  border: 1px solid #d32f2f !important;
}
.p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

.p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;

  .p-progressbar-value {
    background-color: #00acad;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.p-datepicker {
  min-width: 25rem;

  td {
    font-weight: 400;
  }
}

.p-datatable.p-datatable-customers {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}
.p-datatable .p-sortable-column {
  width: auto;
}
.p-column-filter {
  width: 100%;
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #f8b505 !important;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #f8b3057a !important;
  outline: 0 none;
}
@media screen and (max-width: 64em) {
  .p-datatable {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        border: 0 none !important;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4rem 1rem -0.4rem -0.4rem;
          font-weight: bold;
        }
      }
    }
  }
}
.p-paginator .p-paginator-current {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #000 !important;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #f8b505 !important;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  //background: #f8b30535!important;
  background: #f8b505 !important;
  border-color: #f8b505 !important;
  color: #ffffff;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577 !important;
  border-color: #f8b505 !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #f8b505 !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577 !important;
  border-color: #f8b505 !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #f8b30535 !important;
  color: #000 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30575 !important;
  border-color: #f8b505 !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #f8b505 !important;
  background: #f8b505 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #d4a52d;
  background: #d4a52d;
  color: #ffffff !important;
}
</style>
