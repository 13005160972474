<template>
  <div class="home">
    <coScrollTop altura="100" />
    <coTelFlotante />
    <coEmailFlotante />
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <coHero @loaded="herosLoaded" />
    <coServicios @loaded="serviciosLoaded" />
    <coPerfiles />

    <coEkonBanner />
    <coConozca />
    <coMarcas />
  </div>
</template>

<script>
// @ is an alias to /src
import coScrollTop from "../components/coScrollTop.vue";
import coHero from "../components/coHero.vue";
import coEkonBanner from "../components/coEkonBanner.vue";
import coServicios from "../components/coServicios.vue";
import coPerfiles from "../components/coPerfiles.vue";
import coMarcas from "../components/coMarcas.vue";
import coConozca from "../components/coConozca.vue";
import coTelFlotante from "../components/coTelFlotante.vue";
import coEmailFlotante from "../components/coEmailFlotante.vue";
import ViewService from "../services/view.service.js"
import API_URL from "../backend.js"
export default {
  name: "vHome",
  components: {
    coScrollTop: coScrollTop,
    coMarcas: coMarcas,
    coConozca: coConozca,
    coHero,
    coEkonBanner,
    coServicios,
    coPerfiles,
    coTelFlotante,
    coEmailFlotante,
  },
  data() {
    return {
      isServiciosLoaded: false,
      isHerosLoaded: false,
      loader: null,
    };
  },

  mounted() {
    ViewService.getDatosWeb().then((response)=>{
      const logo = response.data[0].xlogo;
      this.loader = this.$vs.loading({
      type: "square",
      color: "#f8b505",
    });
    setTimeout(() => {
      let loaderDIV = document.getElementsByClassName(
        "vs-loading__load__animation"
      )[0];
      loaderDIV.innerHTML = "";
      const imgLogo = document.createElement("img");
      imgLogo.src = API_URL+logo;
      imgLogo.style = "width:200px; z-index: 99999999; border-radius:10px; transition: width 0.4s ease-in-out";
      
      loaderDIV.addEventListener("mouseover", (e) => {
        e.target.style.width="300px";
      })
      loaderDIV.addEventListener("mouseout", (e) => {
        e.target.style.width="200px";
      })
      loaderDIV.appendChild(imgLogo);
    }, 5);
    });
    
  },
  methods: {
    serviciosLoaded(e) {
      this.isServiciosLoaded = e;
      this.closeLoader();
    },
    herosLoaded(e) {
      this.isHerosLoaded = e;
      this.closeLoader();
    },
    closeLoader() {
      if (this.isServiciosLoaded && this.isHerosLoaded) {
        this.loader.close();      
      }
    },
  },
};
</script>
<style lang="scss"></style>
