import { render, staticRenderFns } from "./coTelFlotante.vue?vue&type=template&id=29b4ef8e&scoped=true&lang=html&"
import script from "./coTelFlotante.vue?vue&type=script&lang=js&"
export * from "./coTelFlotante.vue?vue&type=script&lang=js&"
import style0 from "./coTelFlotante.vue?vue&type=style&index=0&id=29b4ef8e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29b4ef8e",
  null
  
)

export default component.exports