<template lang="html">
  <section class="co-perfiles mt-5">
    <div class="surface-0 text-center">
      <h1 class="mb-3 font-bold text-5xl">
        <span class="text-900">Un producto, </span>
        <span class="text-primary">Múltiples soluciones</span>
      </h1>
      <!-- <div class="text-700 text-sm mb-6">
        Ac turpis egestas maecenas pharetra convallis posuere morbi leo urna.
      </div> -->
      <div class="d-flex flex-wrap justify-content-evenly align-items-center mx-3x1">
        <router-link
          v-for="perfil in perfiles"
          :key="perfil.xperfilid"
          class="col-12 col-sm-6 col-xl-4 mb-4 px-3 perfil"
          :to="perfil.xto ? perfil.xto : '/'"
        >
          <span
            class="p-3 shadow-2 mb-3 inline-block perfil-icon"
            style="border-radius: 10px"
          >
            <i class="pi text-4xl text-primary" :class="perfil.xicon"></i>
          </span>

          <div class="mb-3 text-lg" style="font-weight: 600;color: #f8b505">{{ perfil.xtitulo }}</div>
          <span class="text-lg text-center md:pl-8 md:pr-8 line-height-3 perfil-desc">{{
            perfil.xdescripcion
          }}</span>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script lang="js">
//import API_URL from '../backend';
import ViewService from '../services/view.service'
  export default  {
    name: 'co-perfiles',
    props: [],
    mounted () {
      ViewService.getPerfiles().then((response)=>{
        this.perfiles= response.data;
      })
    },
    data () {
      return {
        perfiles:[{title:"",icon:"",}]
      }
    },
    methods: {
      suffle(){
        this.perfiles.sort(()=> 0.5 - Math.random())
      }
    },
    computed: {

    }
}
</script>

<style scope lang="scss">
.co-perfiles {
}
.router-link-exact-active {
  text-decoration: none;
}
.text-primary {
  color: #f8b505 !important;
}
.perfil {
  transition: all 0.5s;
  z-index: 2 !important;
  &-desc{
    color:black !important;
    display: block;
    font-weight: initial;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    /* @media (min-width:568px){
      width: 70%;
    } */
  }
  &-icon{
    transition: all 0.5s;
  }
  &:hover {
    transform: translateY(-15px);
        transform: scale(1.03);
    z-index: 99999;
    div{
      transition: all 0.2s;
      font-size:1.225rem !important;
    }

    .perfil-icon{
      background-color: #f8b505;
      i{
        transition: all 0.3s;
        color: white !important;
      }
    }
    
  }
}
</style>
