<template lang="html">
  <nav class="co-sidebar container">
    <vs-sidebar fixed hover-expand reduce v-model="active" open>
      <template #logo>
        <img :src="imgSRC" />
      </template>

      <vs-sidebar-item id="home" to="/admin/dashboard">
        <template #icon>
          <i class="pi pi-home"></i>
        </template>
        Dashboard
      </vs-sidebar-item>
      <vs-sidebar-group>
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="pi pi-desktop"></i>
            </template>
            Home page
          </vs-sidebar-item>
        </template>
        <vs-sidebar-item id="home#navbar" to="/admin/dashboard/home/nav">
          <template #icon>
            <i class="pi pi-bars"></i>
          </template>
          Navbar
        </vs-sidebar-item>
        <vs-sidebar-item id="home#heros" to="/admin/dashboard/home/heros">
          <template #icon>
            <!-- <i class='pi pi-image'></i> -->
            <i class="fas fa-chalkboard"></i>
          </template>
          Heros
        </vs-sidebar-item>
        <vs-sidebar-item id="home#marcas" to="/admin/dashboard/home/marcas">
          <template #icon>
            <i class="pi pi-ticket"></i>
          </template>
          Marcas
        </vs-sidebar-item>
      </vs-sidebar-group>

      <vs-sidebar-item id="Servicios" to="/admin/dashboard/servicios">
        <template #icon>
          <i class="pi pi-th-large"></i>
        </template>
        Servicios
      </vs-sidebar-item>
      <vs-sidebar-item id="Perfiles" to="/admin/dashboard/perfiles">
        <template #icon>
          <i class="pi pi-id-card"></i>
        </template>
        Perfiles
      </vs-sidebar-item>

      <vs-sidebar-group>
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <i class="pi pi-inbox"></i>
            </template>
            Buzón de entrada
          </vs-sidebar-item>
        </template>

        <vs-sidebar-item
          id="mail#contacto"
          to="/admin/dashboard/buzon/contacto"
        >
          <template #icon>
            <i class="pi pi-users"></i>
          </template>
          Contacto
        </vs-sidebar-item>

        <vs-sidebar-item id="mail#cv" to="/admin/dashboard/buzon/cv">
          <template #icon>
            <i class="far fa-id-badge"></i>
          </template>
          Curriculums
        </vs-sidebar-item>

        <vs-sidebar-item id="mail#descargasFolletos" to="/admin/dashboard/buzon/descargasFolletos">
          <template #icon>
            <i class="pi pi-download"></i>
          </template>
          Descargas Folletos
        </vs-sidebar-item>

        <vs-sidebar-item id="mail#config" to="/admin/dashboard/buzon/config">
          <template #icon>
            <i class="pi pi-cog"></i>
          </template>
          Configuración
        </vs-sidebar-item>
      </vs-sidebar-group>
      <vs-sidebar-item id="datosWeb" to="/admin/dashboard/datosWeb">
        <template #icon>
          <i class="pi pi-globe"></i>
        </template>
        Datos web
      </vs-sidebar-item>
      <!--<vs-sidebar-item id="estadisticas" to="/admin/dashboard/estadisticas">
        <template #icon>
          <i class="pi pi-chart-bar"></i>
        </template>
        Estadísticas
      </vs-sidebar-item>-->
      <template #footer>
        <vs-row justify="space-between">
          <vs-button flat animation-type="scale" to="/">
            <i class="pi pi-globe"></i><template #animate> Web </template>
          </vs-button>
          <vs-button danger flat animation-type="scale" @click="logout">
            <i class="pi pi-sign-out"></i>
            <template #animate> Salir </template>
          </vs-button>
        </vs-row>
        
      </template>
    </vs-sidebar>
  </nav>
</template>

<script lang="js">
import API_URL from"../../backend.js"

  export default  {
    name: 'co-sidebar',
    props: [],
    mounted () {

    },
    data () {
      return {
        imgSRC: API_URL+'/imagenes/extom/horizontal_on_white_by_logaster.jpeg',
        active:'home'
      }
    },
    methods: {
      logout(){
        this.$store.dispatch('auth/logout');
        this.$router.push('/admin')
      }

    },
    computed: {

    }
}
</script>

<style lang="scss">
.co-sidebar {
}
</style>
