<template>
  <header class="co-header p-sticky">
    <Menubar :model="itemsNavbar" class="pt-3 pb-1 text-lg">
      <template #start>
        <router-link to="/">
          <img alt="logo" :src="logo" height="70" class="p-mr-2" :key="componentKey" ref="navbar" />
        </router-link>
      </template>
    </Menubar>
  </header>
</template>

<script lang="js">
//import axios from 'axios'
import ViewService from '../services/view.service'
import API_URL from "../backend";

  export default  {
    name: 'co-header',
    props: [],
    mounted () {
      ViewService.getDatosWeb().then((response)=>{
        this.logo =  API_URL+response.data[0].xlogo;
      })
      ViewService.getNavbar().then((response)=>{
        this.itemsNavbar =  this.parsearNav(response.data);
        this.itemsNavbar.forEach((item)=>{
          ViewService.getNavbarHijos(item.xnavItemid).then((response)=>{
            const itemsHijos = this.parsearNav(response.data);
            if(itemsHijos.length > 0){
              item.items = itemsHijos;
              item.items.forEach((itemHijo)=>{
                ViewService.getNavbarHijos(itemHijo.xnavItemid).then((response)=>{
                  const itemsNietos = this.parsearNav(response.data);
                  if(itemsNietos.length > 0){
                    itemHijo.items = itemsNietos;
                  }

                })
              })//item.items.forEach((itemHijo)=>{
            }
          })// ViewService.getNavbarHijos(item.xnavItemid).then((response)=>{
             
        })//this.itemsNavbar.forEach((item)=>{
          //this.forceRerender()
      })

    },
    data () {
      return {
        itemsMegamenu:[],
        itemsNavbar:[{}],
        logo:'',
        componentKey: 0,
      }
    },
    methods: {
      forceRerender() {
        this.componentKey += 1;
        this.$refs.navbar.click();
      },
      parsearNav(navitem){
        navitem.map((element)=>{
          element.icon = element.xicon;
          element.label = element.xlabel;
          element.to = element.xto;
          delete element.xicon;
          delete element.xlabel;
          //delete element.xto;
          if(element.items == null || element.items.length == 0){
            delete element.items;
            delete element.to;
            if(element.xto){
              element.command = (event)=>{
                this.$router.replace(event.item.xto, function (){location.reload()});
              }
            }

          }else{
            delete element.to;
            element.items = this.parsearNav(element.items);

          }
          delete element.xvisible;
        });
        return navitem;
      }
    },
    computed: {

    }
}
</script>

<style lang="css">
.co-header {
  top: 0.01px;
  position: sticky;
  z-index: 9999;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #f8b3057a !important;
}
.p-menubar .p-menubar-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b3057a !important;
}
.p-menubar,
.p-submenu-list {
  background-color: #fffcf6 !important;
  background-color: #ffffff !important;
}
.p-menubar {
  background-color: #ffffff9c !important;
  font-weight: bold !important;
}
.p-menubar:hover,
.p-menubar:active {
  background-color: #ffffff !important;
}
.p-submenu-list {
  width: fit-content !important;
}
.p-menubar-button {
  margin-left: auto !important;
}
.p-menubar-root-list {
  margin-left: auto !important;
  margin-right: 3rem !important;
}
.p-menuitem {
  height: 100% !important;
  width: fit-content;
  white-space: nowrap;
}
.p-menuitem:hover {
  background-color: #fffcf6 !important;
}
.p-menuitem-link:hover {
  border-bottom: solid;
  border-bottom-width: 2px;
  border-bottom-color: #f8b505;
  color: #f8b505 !important;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #fffcf6 !important;
}
.p-menuitem-link:hover > *,
.router-link-exact-active > * {
  color: #f8b505 !important;
  font-weight: 600;
}
.btn-header-outlined-bottom {
  border-color: #d99e04 !important;
  border-bottom: solid;
  border-bottom-width: 2px;
  padding: 0% !important;
}
.btn-header-outlined-top {
  border-color: #d99e04 !important;
  border-top: solid;
  border-top-width: 2px;
  padding: 0% !important;
}
.btn-header-outlined-right {
  border-color: #d99e04 !important;
  border-right: solid;
  border-right-width: 2px;
  padding: 0% !important;
}
.btn-header-outlined-bottom *,
.btn-header-outlined-top * {
  color: #d99e04 !important;
  font-weight: 600;
}
</style>
