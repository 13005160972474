<template>
    <div class="contact">
        <coContact/>
        <hr>
        <coGoogleMaps/>
        <br>
    </div>
</template>
<script>
import coContact from '../components/coContact.vue';
import coGoogleMaps from '../components/coGoogleMaps.vue';
export default {
    name: "vContact",
   components:{       
       coContact:coContact,
       coGoogleMaps:coGoogleMaps
   },
   mounted(){
   }
}
</script>