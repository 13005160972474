import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + '/auth/login', user)
      .then((response, error) => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify({token:response.data.token}));
          return response.data;
        }
        if(error) {
          Promise.reject(error)
        }
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + '/auth/signup', user).then((response, error) => {
      if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
        return response;
      }
      if(error) {
        Promise.reject(error)
      }
    });
  }

  esAdministrador() {
    return axios.get(API_URL + '/auth/esAdministrador',{ headers: authHeader() });
  }
}

export default new AuthService();