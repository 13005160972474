<template>
  <div class="demo-container mt-5" v-if="visible">
    <h2 class="text-900 font-bold text-3xl">
      Nuestros clientes <span class="text-primary">nos conocen</span>
    </h2>
    <Carousel
      v-if="marcas.length"
      :responsiveOptions="responsiveCarousel"
      :numVisible="4" 
      :numScroll="3"
      :value="marcas"
      :circular="true"
      :autoplayInterval="5000"
      indicatorsContentClass="d-none"
      containerClass=""
    >
      <template #item="marca">
        <a
          target="_blank"
          v-if="marca.data.xto && marca.data.xto != 'null'"
          :href="marca.data.xto"
        >
          <img :src="marca.data.ximg" alt="imagen" class="marca" />
        </a>
        <img
          v-if="!marca.data.xto"
          :src="marca.data.ximg"
          alt="imagen"
          class="marca"
        />
      </template>
    </Carousel>
  </div>
</template>
<script>
import API_URL from "../backend";
import ViewService from "../services/view.service";
export default {
  data: function () {
    return {
      marcas: [],
      visible: true,

      responsiveCarousel: [
        /* {
          breakpoint:"568px",
          numVisible:1,
          numScroll:1
        },
        {
          breakpoint:"992px",
          numVisible:2,
          numScroll:1
        },
        {
          breakpoint:"1200px",
          numVisible:3,
          numScroll:2
        } */
        {
          breakpoint: "1024px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "600px",
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: "480px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },
  mounted() {
    ViewService.getMarcas().then((response) => {
      this.marcas = response.data;
      for (let i = 0; i < this.marcas.length; i++) {
        this.marcas[i].ximg = API_URL + this.marcas[i].ximg;
      }
    });
  },
};
</script>
<style scoped lang="scss">
.marca {
  width: 200px;
  margin: 3vh;
  /* -webkit-filter: grayscale(100%); 
  filter: grayscale(100%); */
}
</style>
