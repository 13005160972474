import axios from "axios";
import API_URL from "../backend";
import authHeader from "./auth-header.service";

class TrabajaNosotrosService {
  getDescargarCCVV(nombre) {
    const axiosRequest = {
      method: "get",
      url: API_URL + "/admin/trabajaNosotros/cvPDF/" + nombre,
      responseType: 'blob',
      headers: authHeader(),
    };
    return axios(axiosRequest);
  }
  getTodos(){
    const axiosRequest = {
        method: "get",
        url: API_URL + "/admin/trabajaNosotros/todos",
        headers: authHeader(),
      };
      return axios(axiosRequest);
  }
  borrar(id){
    const axiosRequest = {
      method: "delete",
      url: API_URL + "/admin/trabajaNosotros/borrar/"+id,
      headers: authHeader(),
    };
    return axios(axiosRequest);
  }
}

export default new TrabajaNosotrosService()