<template>
  <div id="app" class="">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" integrity="sha512-9usAa10IRO0HhonpyAIVpjrylPvoDwiPUiKdWk5t3PyolY1cOd4DSE0Ga+ri4AuTroPR5aQvXU9xC6qOPnzFeg==" crossorigin="anonymous" referrerpolicy="no-referrer" />
    <link
      href="https://fonts.googleapis.com/css?family=Dancing+Script:400,700|Josefin+Sans:300,400,600,700|Nunito:300,400,600,700"
      rel="stylesheet"
    />
    <coHeader v-if="routeAdmin" />
    <coCookies/>
    <transition name="route" mode="out-in">
      <router-view/>
    </transition>
    <coScrollTop altura="100" />

    <coFooter v-if="routeAdmin"/>
    
  </div>
</template>
<script>
import coHeader from "./components/coHeader.vue";
import coFooter from "./components/coFooter.vue";
import coScrollTop from "./components/coScrollTop.vue";
//import AuthService from "./services/auth.service";

import coCookies from "./components/coCookies.vue";
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Extom Consulting",
    // all titles will be injected into this template
    titleTemplate: "%s | Consultoria | ERP | Ekon | Informática |",
  },
  components: {
    coScrollTop: coScrollTop,
    coHeader,
    coFooter,
    coCookies
  },
  data() {
    return {
      adminView:true
    }
  },
  computed: {
    routeAdmin(){
      return this.$route.fullPath.split('/')[1] != "admin"
    }
  },
  mounted () {
    console.log(this.$route)
    if(this.$route.path.includes("/admin")){
      this.$Tawk.$endChat();
      this.adminView = true
    }else{this.adminView = false}
  },
};
</script>

<style lang="scss">
/* Routes transitions */
.route-enter-from {
  opacity: 0;
}
.route-enter-active {
  transition: all 0.3s ease-in-out;
  transition-delay: 0.2s;
}
.route-leave-to {
  opacity: 0;
}
.route-leave-active {
  transition: all 0.3s ease-out;
}
:root {
  --bs-primary-rgb: 248, 181, 5 !important;
  --bs-primary: #f8b505 !important;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577;
  border-color: #f8b505;
}
.p-input-icon-left {
  width: none !important;
  @media (min-width: 568px) {
    width: inherit !important;
  }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30575;
  border-color: #f8b505;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #f8b505;
  background: #f8b505;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #d4a52d;
  background: #d4a52d;
  color: #ffffff;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30580;
  border-color: #f8b505;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #f8b305d5;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #f8b30580;
}
.p-invalid .p-fileupload-content {
  border: 1px solid;
  border-color: #dc3545;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #f8b505 !important;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 181, 5, 0.5) !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  box-shadow: inset 0 0 0 0.15rem rgba(248, 181, 5, 0.5) !important;
}
.text-primary {
  color: #f8b505 !important;
}
.p-button {
  background-color: #f8b505 !important;
  border: 1px solid #f8b505 !important;
  &:hover {
    border-color: #f8b505 !important;
    background-color: darken(#f8b505, 3%) !important;
    color: white;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 179, 5, 0.5) !important;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
  text-decoration: none;
}
a {
  text-decoration: none;
  color: #42b983;
}
</style>
