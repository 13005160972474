import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
Vue.use(VueMeta);

/**
 * TAWK.TO
 */
 import Tawk from 'vue-tawk'
  
 Vue.use(Tawk, {
     tawkSrc: 'https://embed.tawk.to/621cad791ffac05b1d7c35da/1ft00b8oq'
 })
/**
 * PRIMEVUE
 */
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/bootstrap4-light-purple/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";
Vue.use(PrimeVue);

import 'bootstrap/dist/css/bootstrap.min.css'
//import 'jquery/src/jquery.js'
//import 'bootstrap/dist/js/bootstrap.min.js'


import Button from 'primevue/button';
import Menubar from 'primevue/menubar';
import MegaMenu from 'primevue/megamenu';
import Carousel from 'primevue/carousel';
import Card from 'primevue/card';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import Checkbox from 'primevue/checkbox';
import InputMask from 'primevue/inputmask';
import MultiSelect from 'primevue/multiselect';
import Toast from 'primevue/toast';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Fieldset from 'primevue/fieldset';
import ScrollPanel from 'primevue/scrollpanel';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import Tooltip from 'primevue/tooltip';
Vue.directive('tooltip', Tooltip);
import OverlayPanel from 'primevue/overlaypanel';
import Toolbar from 'primevue/toolbar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Dialog from 'primevue/dialog';
import ContextMenu from 'primevue/contextmenu';
import InputSwitch from 'primevue/inputswitch';
import AutoComplete from 'primevue/autocomplete';
import InputNumber from 'primevue/inputnumber';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import Password from 'primevue/password';
import PSPDFKit from './components/BackOffice/PSPDFKit'
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from 'primevue/confirmpopup';
Vue.use(ConfirmationService);
import Chart from 'primevue/chart';


import * as VueGoogleMaps from 'vue2-google-maps'


Vue.component('Button', Button);
Vue.component('Menubar', Menubar);
Vue.component('MegaMenu', MegaMenu);
Vue.component('Carousel', Carousel);
Vue.component('Card', Card);
Vue.component('InputText', InputText);
Vue.component('Dropdown', Dropdown);
Vue.component('Textarea', Textarea);
Vue.component('FileUpload', FileUpload);
Vue.component('Checkbox', Checkbox);
Vue.component('InputMask', InputMask);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Toast', Toast);
Vue.component('Accordion', Accordion);
Vue.component('AccordionTab', AccordionTab);
Vue.component('Fieldset', Fieldset);
Vue.component('ScrollPanel', ScrollPanel);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('Toolbar', Toolbar);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('Calendar', Calendar);
Vue.component('Dialog', Dialog);
Vue.component('ContextMenu', ContextMenu);
Vue.component('InputSwitch', InputSwitch);
Vue.component('AutoComplete', AutoComplete);
Vue.component('InputNumber', InputNumber);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('InlineMessage', InlineMessage);
Vue.component('Message', Message);
Vue.component('Password', Password);
Vue.component('PSPDFKit', PSPDFKit);
Vue.component('ConfirmPopup', ConfirmPopup);
Vue.component('Chart', Chart);

/* VUE COOKIES */
Vue.use(require('vue-cookies'))
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// set default config
Vue.$cookies.config('7d')

// set global cookie
Vue.$cookies.set('theme','default');
Vue.$cookies.set('hover-time','1s');
/*              */

Vue.use(VueGoogleMaps, {  
    load:{
      key: 'AIzaSyCBWmX2DUKpMtG5WW8-uun5PnQF1uCLwAs'
    }
  }
)

import ToastService from 'primevue/toastservice';
Vue.use(ToastService);
Vue.use(PrimeVue, {
  devtool: 'source-map',
  ripple: true,
  locale: {
    accept: 'Aceptar',
    reject: 'Rechazar',
    choose: 'Elegir',
    upload: 'Subir',
    cancel: 'Cancelar',
    dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    dayNamesMin: 	["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
    monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
    monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    today: "Hoy",
    weak: "Débil",
    medium: "Medio",
    strong: "Fuerte"
    //...
  }
});





/**
 * Vuesax
*/
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  colors: {
    primary:'#f8b505',
    success:'rgb(23, 201, 100)',
    danger:'rgb(242, 19, 93)',
    warning:'rgb(255, 130, 0)',
    dark:'rgb(36, 33, 69)'
  }
})

//Vue.config.productionTip = true

new Vue({
  router,
  store,
  render: h => h(App),
  
}).$mount('#app')
