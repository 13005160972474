<template lang="html">

  <p class="co-email-flotante d-flex align-items-center">
    <a class="pl-2 pr-2 telf-flotante-num order-2" :href="'mailto:'+email"
      >{{email}}</a
    >
    <a :href="'mailto:'+email" class="text-lg fas fa-paper-plane email-flotante-ico order-1"></a>
  </p>

</template>

<script lang="js">
import ViewService from '../services/view.service'  
  export default  {
    name: 'co-email-flotante',
    props: [],
    mounted () {
      ViewService.getDatosWeb().then((response)=>{
        this.email = response.data[0].xemail;
      })
    },
    data () {
      return {
        email:'',
      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
a {
  text-decoration: none !important;
}
.co-email-flotante {
  position: fixed;
  z-index: 1006;
  bottom:40px;
  left: 25px;

  cursor: pointer;
  &:hover {
    .telf-flotante-num {
      opacity: 1;
      transform: translateX(-5px);
    }
  }
}
.telf-flotante-ico {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  transform: rotate(90deg);
  padding: 15px;
  background-color: #f8b505;
  color: white;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}
.email-flotante-ico {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  padding: 15px;
  background-color: #f8b505;
  color: white;
  z-index: 999;
}
.telf-flotante-num {
  display: inline-block;
  -webkit-transform: translateX(45px);
  transform: translateX(-55px);
  opacity: 0;
  transform: translateX(-45px);
  border-radius: 1em;
  border: 1px solid #f8b505;
  border-left: none;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #f8b505;
  transition: all 0.3s ease-in-out;
  font-weight: 800;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.coccoc-alo-ph-circle {
  width: 60px;
  height: 60px;
  left: -6.5px;
  position: absolute;

  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 3px solid rgba(30, 30, 30, 0.5);
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.coccoc-alo-ph-circle-fill {
  width: 80px;
  height: 80px;
  left: -16.5px;
  position: absolute;
  background-color: #f8b505;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-o-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }
  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}
@-moz-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-o-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }
  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(90) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(75deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(105deg) scale(1) skew(1deg);
  }
  30% {
    transform: rotate(75deg) scale(1) skew(1deg);
  }
  40% {
    transform: rotate(105deg) scale(1) skew(1deg);
  }
  50% {
    transform: rotate(90) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(90) scale(1) skew(1deg);
  }
}
</style>
