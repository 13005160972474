<template>
  <div class="template bg-extom">
    <div class="container">
      <h3>¿Tiene alguna duda?</h3>
      <h4>
        Estamos a su servicio, contáctenos y le atenderemos lo antes posible
      </h4>
      <br />
      <router-link :to="url + origen1 + origen2">
        <Button label="Contacto" />
      </router-link>
    </div>
  </div>
</template>
<script>
import Button from "primevue/button";
export default {
  components: {
    Button: Button,
  },
  props:{
    origen1:{
      default:"",
    },
    origen2:{
      default:"",
    }
  },
  data() {
    return {
      url: "/contact",
    };
  }
};
</script>
<style>
.template {
  padding: 50px !important;
}
a {
  color: none !important;
  text-decoration: none !important;
}
</style>
